@import '~bootstrap/scss/_functions.scss';
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";


.tntg-speaker {
  border-bottom: 1px solid transparentize($black, .9);

  &-wrap {
    .tntg-speaker:first-child {
      border-top: 1px solid transparentize($black, .9);
    }
  }

  &-img {
    @include media-breakpoint-down(md) {
      border-radius: 50%;
    }
  }

  &-name,
  &-description {
    & > :last-child {
      margin-bottom: 0;
    }
  }
}
