@import '~bootstrap/scss/_functions.scss';
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
$transparent: rgba($white, 0);
.background-section-image {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-left {
    @include media-breakpoint-up(lg) {
      background-position: left center;
      background-size: 70%;
    }
  }

  &-right,
  &-right-full-gradient {
    @include media-breakpoint-up(lg) {
      background-position: right center;
      background-size: 70%;
    }
  }
}

@each $color, $colorCode in map-merge($colors, $theme-colors) {
  .background-section-image-#{$color} {
    &::before {
      background: transparentize($colorCode, .1);
    }

    &.background-section-image-left {
      @include media-breakpoint-up(lg) {
        &::before { background: linear-gradient(to right, $transparent 20%, $colorCode 45%); }
      }
    }

    &.background-section-image-right {
      @include media-breakpoint-up(lg) {
        &::before { background: linear-gradient(to right, $colorCode 45%, $transparent 65%); }
      }

      &-full-gradient {
        &::before { background: linear-gradient(to right, $colorCode 30%, transparentize($colorCode, .15) 70%); }

        @include media-breakpoint-up(lg) {
          &::before { background: linear-gradient(to right, $colorCode 50%, transparentize($colorCode, .2) 75%); }
        }
      }
    }
  }
}

// custom overrides
.background-section-image {
  &.background-section-image-mother-daughter {
    @include media-breakpoint-up(lg) {
      background-position: 190% center;
      background-size: 90%;

      &::before { background: linear-gradient(to right, $black 45%, transparentize($black,.2) 65%, $transparent 85%); }
    }

    @include media-breakpoint-up(xl) {
      background-position: right center;
      background-size: 70%;

      &::before { background: linear-gradient(to right, $black 50%, $transparent 70%); }
    }

    @media (min-width: 1400px) {
      background-size: 60%;
    }
  }
}
