.resourcesListItem {
  border: none;
  // &:nth-child(odd) {

  //   border-bottom: 1px solid red;
  //   border-right: 1px solid red;
  // }
  border-radius: 0;
  max-height: 120px;
  > div {
  min-width: 160px;
    position: relative;
    height: 120px;
    max-height: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    > h4 {
      color: white;
      position: absolute;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      top: 46%;
      right: 18%;
      margin: auto;
      width: 66%;
      font-size: 14px;
      font-weight: small;
    }
  }
}
