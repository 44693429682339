.headerUserInfo{
  &Container{
    padding-right: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &Dropdown{
    button{
      cursor: pointer;
      max-width: 100vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &Img {
    text-align: center;
    width: 32px;
    height: 32px;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 8px;
    display: inline-block;
  }
}
