
.option {
  height: 75px;
  border-right: 1px solid grey !important;
  border-left: 1px solid grey !important;
  padding-left: 12px;
  padding-right: 0;
}

.onlyOption {
  border-radius: 8px 8px 8px 8px !important;
  border: 1px solid grey !important; 
}

.topOption {
  border-radius: 8px 8px 0 0 !important;
  border-top: 1px solid grey !important;
  border-bottom: 0 !important;
}

.middleOption {
  border-top: 1px solid grey !important;
  border-radius: 0 0 0 0 !important;
  border-bottom: 0 !important;
}

.lastOption {
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0 0 8px 8px !important;
}

.checkedGradient {
  background-image: linear-gradient(-180deg, #EEEEEE 0%, #D8D8D8 100%);
}

.uncheckedGradient {
  background-image: linear-gradient(0deg, #FFFFFF 0%, #E4E4E4 100%);
}

.black {
  color: black;
  font-weight: 400;
}

.green {
  color: green;
  font-weight: 400;
}

.sup {
  font-size: 16px !important;
  padding-right: 2px;
  transform: translateY(-.55em);
}

.dynamicSize {

  font-size: 1em;
}


@media only screen and (min-width: 425px) {
  .dynamicSize {
    font-size: 1.5em;
    margin-left: 8px;
  }
} 

