@include media-breakpoint-down(xs){
  .zoomWebinarContainer,
  .zoomWebinarContainer > .row,
  .zoomWebinarContainer > .row > .col {
    padding-left: 0;
    padding-right: 0;
    & > .row{
      padding-left: 0;
      padding-right: 0;
      & > .col{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.zoomWebinarContainer{
  margin-top: 3rem;
  margin-bottom: 3rem;
  .card-header{
    background: $white;
    border-bottom: none;
    padding: calc($grid-gutter-width*3/2);
    padding-bottom: 0;
  }

  .zoomWebinarBioImg {
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 125px;
    @include bp (md) {
      height: 150px;
    }
    // @include bp(lg) {
    //   height: 200px;
    // }
  }
}
.zoomWebinarDownload {
  cursor: pointer;
  margin-top: 36px;
  text-align: center;
  svg {
    font-size: 36px;
  }
  a {
    color: $FRNGrey;
    text-decoration: none;
  }
}
