.internal-ad-container {
    display: flex;
    border-radius: '0.375rem';
    height: '450px';
    min-height: '450px';
  }
  
  .image-container {
    
    flex: 2;
    position: relative;
    background-image: linear-gradient(to right, white, transparent 260px), url('https://cdn.foodrevolution.org/members/assets/img/iStock-1315353793.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  
    height: 100%;
    max-height: 100%;
   
  }
  
  .content-container {
  color:black;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  /* overflow: visible; */
  /* white-space: nowrap; */
  align-self: flex-start;
  }
  
  /* Additional styling for the content within the content-container */
  .content-container h1 {
    font-size: 3rem; 
    font-weight: bold;
  }
  
  .content-container h3 {
    /* font-size: 18px; */
    font-weight: bold;
  }
  
  .content-container p {
    font-size: 16px;
  }
  
  .content-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .mobile-image-container {
    position: relative;
  }
  
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top,  white, transparent 320px);
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.375rem;
  }

  @media (max-width: 767px) {
    .gradient-overlay {
      
      background-image: linear-gradient(to top,  white, transparent 100px);
    }
    .content-container h1, h3 {
      font-size: 2.5rem;
      
    }
    
    
  }
  