.checkboxSuccess input{
  background-color: #5cb85c;
  border-color: #5cb85c;
  cursor:pointer;
}
.pointerGlobal{
  cursor: pointer;
}
.closeOutFilter{
  color:white;
  cursor:pointer;
  position: absolute;
  right:4px;
  top:50%;
  margin-top:-11px;
  
}
.closeOutModal{
  top:0;
  right:4px;
  font-size:18px;
  position: absolute;
  cursor:pointer;
  z-index:1000;
}

.modalGlobal
{
  word-wrap: 'break-word';
}
.tagsGlobal{
  line-height: 1.1;
}
.lineThrough{
  text-decoration: line-through;
}