.tagStyles {
  background-color: $tag-blue;
  min-width: 64px;
  max-width: 72px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  @include font-light;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}
