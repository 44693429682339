
//for reference only (bootstrap variables):
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;
.breadCrumbs{
   font-size: $font-size-2xsmall;
  @include bp(sm) {
   font-size: $font-size-regular;
  }
}
.circle-image{
  width: 35px;
  height:35px;
  border-radius: 50%;
  background-position: top;
  background-size: cover;
}
.comingSoon{
  position: absolute;
  top:0;
  left:0;
  height:100%;
  width:100%;
  background:black;
  opacity:.8;
  color:white;
  H5,H3{
    color:white;
    text-align:center;
  }

}
.btn-secondary{
    background:$secondary;
    max-width:100%;
    white-space: normal;

}
.greyedOut{
  opacity:.5;
}

.greyBorder{
    border:thin solid grey;
}

.EP{
&Wrapper{
  a{
    color:$FRNMediumGreyBackground;
  }

  // p{
  //   font-size:22px;}
}

//holds the title of the each broad cast day on home page (i.e. day 1)
&-broadcastTitle
{
  position:relative;
  background: lighten($FRNPurple, 5%);
  color: white;
  height: 100px;

  &:after{
    content: '';
    background-image: url('images/card-bg-veggies.svg');
    background-size: cover;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

   @include bp(md) {
   height:120px;
  }
}


//vertically centers the 'day 1', etc.   adjust font-size accordingly
&-textCenter{
  //mobile
  font-size: $h1-font-size;//$font-size-5xl;
  line-height: 1.1;//$font-size-5xl;
}

}
.EP-day:hover{
  cursor: pointer;
}

.EP-day-seakerName:hover{
  text-decoration: underline;
}
