/* styles.css */
/* styles.css */
/* styles.css */


.step {
  transition: opacity 1s, transform 1s; /* Smooth transition for opacity and transform */
  /* opacity: 0; */
  transform: translateX(-100%); /* Start off-screen to the left */
  max-height: 0; /* Collapse the step */
   overflow: hidden; 
  width: 100%; /* Full width */
  min-width: 100%; /* Minimum full width */
  /* Don't need visibility here since opacity and max-height are used */
}



.step.active {
  opacity: 1;
  transform: translateX(0); /* Slide into view */
  max-height: 22000px; /* Large max-height to ensure content is shown */
  min-width: 100%; /* Minimum full width */
  /* min-height: 1500px; Minimum height to show content */
  z-index: 2; /* Above non-active steps */
  /* visibility: visible; is not necessary due to opacity: 1; */
}




.step.out-forward {
  /* Styles for moving out to the right */
  /* transform: translateX(100%); */
  transform: translateX(-100%);
  
  transition: opacity 1s, transform 1s; /* Smooth transition for opacity and transform */
}

.step.out-backward {
  /* Styles for moving out to the left */
  transform: translateX(100%);
  transition: opacity 1s, transform 1s; /* Smooth transition for opacity and transform */

}
.step.community-live
{
  min-height: 0px;
}


.icon {
    transition: opacity 0.3s;
}

.my-container {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media screen and (max-width: 768px) {
    .my-container {
      width: 90%;
    }
  }

  .o-button-wrapper
  {
    display: 'flex'; 
    flex-direction: 'column'; 
    align-items: 'center'; 
    justify-content: 'center';
   
  }
  
  .o-divider{
    width: 100%;
    height:50px;

  }
  .o-nav-buttons{
    display:flex !important;
  }
  .o-nav-buttons button{
    font-size:30px;
  }
  .forum-layout{
    
    margin-bottom:-42px; 
  }
  @media only screen and (max-width: 768px) {
    .o-nav-buttons button {
    
      font-size: 18px;    

    }
    .forum-layout
    {
      display:inline !important;
    }
  }