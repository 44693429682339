
$line-height: 2;

.surveyResultsContainer {
  @include addLeadingMargin;
  @include addTrailingMargin;
}

.surveyResultsHeadline {
  @include contentTitle;
  @include addLeadingMargin(2);
  @include addTrailingMargin(2);
  padding-left: 0;
}

.surveyResultsQuestion {
  @include addTrailingMargin(2);
}

.surveyResultsQuestionText {
  text-align: center;
  background-color: $FRNGreen;
  padding: $generic-left-margin;
  color: white;
  div {
    margin-right: $generic-left-margin;
    margin-left: $generic-left-margin;
  }
}

.surveyResultsAnswers {
  text-align: center;
  padding: $generic-left-margin;
  span {
    display: block;
    &:nth-of-type(2) {
      @include pageTitle;
      font-weight: 900;
      color: $FRNGreen;
    }
  }
}