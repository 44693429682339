.courseContainer {
  border: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0;
  @include addTrailingMargin;
}

.courseDescription {
  @include addPadding;
  @include addTrailingMargin;
}

.courseHackySpacing {
  width: 100%;
  @include addTrailingMargin(.33);
}

.courseTitle {
  @include addPadding;
  @include pageTitle;
}
