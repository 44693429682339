.dashboardHeader {
  font-size: $font-size-small;

  @include bp(lg) {
    font-size: $font-size-2xlarge;
  }
}

.dashboardHeader h1 {
  margin-top: $headings-margin-bottom;
  margin-bottom: $headings-margin-bottom;
}

.moduleProgressWrapper {
  //pt-0 mt-0 px-2 sticky-top zIndex100 bg-dark" style={{top: '70px'}}
  padding-top: 0px;
  margin-top: 0px;
  background: #1e2629; //TODO figure out why variables are not working$FRNDarkGreyBackground;
  top: 70px;
  // z-index:900;

  height: 60px;

  @include bp(lg) {
    // margin-left:-220px;
    // padding-left:250px;
    // padding-right:30px;
  }
}

.moduleProgress {
  background: $FRNDarkGreyBackground;
}

.premieresAtOverlay {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
  height: 60px;

  @include bp(lg) {
    height: 71px !important;
  }
}

.premieresAtOverlay .icon,
.lessonLocked .icon {
  position: absolute;
  left: 32px;
  top: 10px;
  padding: 0px;
  margin: 0px;
  font-size: 30px;

  @include bp(lg) {
    left: 42px !important;
    top: 16px !important;
  }
}

.premieresAtOverlay {
  color: white;
  height: 1.3em;
  font-size: 1.3em;
}

.titleOverlay {
  position: absolute;
  color: white;
  top: 24px;
  left: 140px;
  font-size: 0.8em !important;
  font-weight: 700;
  padding: 0px;
  opacity: .5;

}

.timeOverlay {
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: .8em !important;

  @include bp(lg) {
    top: 18px;

  }
}

.timeOverlay span {

  font-size: 1.3em;
  font-weight: 700;
  line-height: .7em;

}

.premieresAtBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
  background: black;
  height: 60px;
  opacity: .8 !important;

  @include bp(lg) {
    height: 71px !important;

  }

}

.premieresAtOverlay h6 {
  color: white;
}


.cardPremiere {
  height: 60px;

  @include bp(lg) {
    height: 71px;
  }
}

.moduleHeader div:first-child {
  font-size: $font-size-4xl;
  font-weight: bold;
  color: black;

}

.moduleHeader div:last-child {}

.lessonsHeader span:first-child {
  font-size: $font-size-lg-xl;
  font-weight: 700;
}

.lessonLocked {
  background-color: #222;
}

.lessonLocked .lessonVideoThumbnail {
  opacity: .75;
}