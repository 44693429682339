$side-bar-padding-top: 36px;
$side-bar-padding-left: 24px;


//used to hide sideBarFooter when vertical height is too small
.hideThis{
  display:none !important;
}
.showMoreNav{
    margin-left: -80vw;
    width: 80vw;
    z-index: 3000;
    height:100vw;
    height:100%;
    position: fixed;
    top:0px;
    left:0px;
    margin-top:0px;
    padding-top:10px;
    transform: translateX(-80vw);
    transition: all .4s ease-in-out;
    // background:$FRNDarkGreyBackground;
    color:black;
    background: $FRNGreyLighter;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;

}
.closeMark{
  margin-top:-10px;//offest padding
  float:right;
 
  margin-right:8px;
  font-size:32px;
}
.makeVisible{
   margin-left:0px;
   transform: translateX(0);
}
.showMoreNavItem{
  width:100%;
  border-bottom:1px solid $FRNMediumGreyBackground;
  display: flex;
    align-items: center;

  &Text{
    font-size: 16px;
   
    // display:inline-block;
    @include bp(md) {
      font-size: 22px;
      height:22px;
    }
  }
  &Icon{
    width:55px;
    // display:inline-block;
  }
}

//THE MAIN WRAPPER FOR A PRODUCT
.productSidebarChildren{
    box-sizing: border-box;
    margin-left: 0px;
    width:100%;
      @include bp(lg) {

      padding-left:220px;
       @include print{
      padding-left:0px !important;
    }
    }
   
}
.activeNav{
  color: $FRNGreen;
}
.productSideBarContainer {
  
    box-sizing: border-box;
    min-height: 80px;
    background:$FRNGreyLighter;
    width:100%;
    position:fixed;
    bottom:0;
    z-index:1000;
    color:black;
    transition: all .4s ease-in-out;
    display:flex;
    justify-content: space-between;
   
   @include bp(lg){
        display:block;
        
      }


  &NavItem{
      height:60px;
      margin:10px;
      display:inline-block;
      text-align: center;
      padding:5px; 
      line-height:14px;
      font-size: 14px;
      &:hover{
      cursor: pointer;
      color: $FRNGreen;
      }

     @include bp(lg){
      border-bottom:1px solid $FRNMediumGreyBackground;
      font-size: 16px;
        
      }

    }

//big screen, above lg breakpoint
  @include bp(lg){
    box-shadow: -4px 60px 8px 3px rgba(186,202,213,0.63);
    min-height: 100vh;
    height: 100%;
    width: 80px;
    // z-index just under modal background/ 1040
    z-index: 100;
    position: fixed;
    //Remove from screen
    margin-right: -80px;
    transform: translateX(-80px);
    text-align:center;
    padding-top:80px; //controlled through props and .pptStyles on PPT
    
  &NavItem{
      margin-top:8px;
      height:auto;
  }
  &.makeSideBarVisible {

    transform: translateX(0);
    //for bigger screens
    @include bp(lg) {
      width: 220px;
      margin-right: 0;
    }
  }

  .sideBarFooter {
    padding: 20px;
    @include media-breakpoint-up(lg){
        position: absolute;
        bottom:0;
    }
  }

  .sideBarNavExt {
    background: $FRNDarkPurple;
    color: white;
    padding: 16px 4px;
    height: 63px;
    // justify-content: flex-end;
  }

  .sideBarLogo {
    display: flex;
    justify-content: center;

    padding-top: $side-bar-padding-top;
    text-align: center;
    > svg{
      height: 75px;
    }
  }

  .sideBarMain {
    .sideBarOverview {
      margin-bottom: 16px;
      padding-top: 30px;
      padding-left: $side-bar-padding-left;
      // Make me a mixin?
      span {
        letter-spacing: 1.33px;
      }
    }
  }


  .sideBarSignOut {
    padding-left: $side-bar-padding-left;
    padding-top: 8px;
    height: 63px;
    span {
      cursor: pointer;
    }
  }

  @include print {
    display: none;
  }

  .sideBarArrowIcon {
    path {
      color: white;
    }
    font-size: 24px;
    cursor: pointer;
    margin-left: 24px;
  }
}
}

.pptStyles
{
padding-top:inherit;

@include bp(lg){
  padding-top:160px;
}
}


.customImgIcon{
  width: 20px;
}

.ActivecustomImgIcon{
  -webkit-filter: drop-shadow(83px 0 0 #048D22);
  filter: drop-shadow(83px 0 0 #048D22);
  position: absolute;
  left: -83px;

}

.baseIcon{
  position: relative;
  margin-right: 22px;
}