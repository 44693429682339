.welcomeContainer {
  // width: 100% !important;
  @include addTrailingMargin();
}

.list {
  @include addTrailingMargin(.5);
}

.listTitle {
  @include addLeadingMargin;
  @include addTrailingMargin;
  @include pageTitle;
}

.subTitle {
  @include subTitle;
  @include addTrailingMargin(2);
}
