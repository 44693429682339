@mixin centerText {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  text-align: center;
}

@mixin courseSessions {
  div > a {
    display: block;

    width: 100%;

    color: $FRNGrey;
    border-bottom: 1px solid $FRNGrey;
    text-decoration: none;

    &:last-of-type {
      margin-bottom: 0;
      border-bottom: none;
    }

    > h5 {
      @include addPadding;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 2.85;
      font-weight: 700;
      // text-align: center;
      > div {
        display: inline-block;
        width: 24px;
      }
      @include bp(sm) {
        font-size: 24px;
        > div {
          width: 48px;
        }
      }
    }
  }
}

.sessionsListContainer {
  @include noPadding;
  .sessionsList {
    @include addTrailingMargin(.33);
    @include courseSessions;

    .sessionsListTitle {
      text-align: center;
      @include addTrailingMargin(.5);
      @include contentTitle;
      @include addPadding;
    }
  }
}