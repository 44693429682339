.liveVideoHeader{
    &Inner {
        background: linear-gradient(0deg, transparent, cubic-bezier(0.215, 0.61, 0.16, 1), #000);
        display: flex;
        align-items: top;
    }
    &Video {
        aspect-ratio: 16/9;
        width: 100%;
        max-width: calc(100vh / 9 * 16);
        margin: 0 auto;
    }
    &Comments {
        height: 100%;
        max-height: 100vh;
        flex: 0 0 450px;
        margin: 0 0.75rem;
        overflow-y: scroll;
        overflow-x: hidden;
        background: #fff;
    
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
}