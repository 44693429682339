.welcome-container p
{
   font-size:  2rem;
   margin-bottom: 20px;
}
.welcome-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin-top: 20px;
    padding: 5px;
    border-radius: 8px;
    padding-top:0;
   
    /* background-color: #f9f9f9; */
  }
  
  .welcome-container h1 {
    color: #333;
  }
  
  .welcome-container h3 {
    margin-top: 20px;
  }
  
 
  