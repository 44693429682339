.profileBraintreeFormContainer {
  padding: 0;
  .profileBraintreeFormField {
    height: 24px;
    margin-left: 12px;
    // margin-bottom: 16px; // Set from React comp
    // padding-bottom: 8px;
    border-bottom: 1px solid lightgrey;
  }

  .profileBraintreeFormWarning {
    margin-left: 16px;
    margin-bottom: 8px;
    span {
      color: $FRNRed;
    }
  }
}