.orderProductsContainer {
  margin: 8px 0;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  margin: 6px 0 16px 0;

  @include print {
    display: block;
  }
}
