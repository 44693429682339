.sessionResource {
  @include addTrailingMargin(.75);

  .sessionResourceTitle {
    padding-left: 0 !important;
    margin-left: $generic-left-margin;
  }

  .sessionResourceTitleIcon {
    margin-right: calc($generic-left-margin / 2);
    display: inline-block;
  }

  .sessionResourceRowOne {
    cursor: pointer;
  }

  .sessionResourceTitleText {
    display: inline-block;
  }

  h5 {
    @include addPadding;
    @include contentTitle;
    @include addTrailingMargin(2);
    @include addLeadingMargin;
  }

}
