.userSubscriptionsCancelLink {
  color: $FRNPurple-02;
  text-decoration: underline;
  cursor: pointer;
}

.card.upgradeBannerWrapper {
  display: flex;
  background-color: $FRNPurple-02;
  color: white;
}

.upgradeBannerCopy {
 padding: 1.25rem;
 font-weight: bold;
}

.upgradeBannerCopy > h5 {
  color: inherit;
}
