.onboardTitleModalHeader {
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 8px;

  .onboardTitle {
    text-align: center;
    margin-bottom: 8px;
    span {
      @include font-light;
      font-size: 22px;
    }
  }

  .onboardUserClose {
    text-decoration: underline;
    span {
      color: $FRNGreen;
    }
  }
}

.modalCloseButton {
  text-align: center;
  width: 100%;
  button {
    margin-right: 16px;
    @include profileButton;
  }
}
