
.toolsCardContainer {
  padding: $generic-left-margin;
  @include addTrailingMargin(.75);

  > h5 {
    @include contentTitle;
  }

  > img, a, span {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 600px;
  }

  > span {
    @include addTrailingMargin(2);
  }

  > a, img {
    @include addTrailingMargin(4);
  }
}
