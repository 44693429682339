.facebookCommentsSlideOut {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 100vw;
  z-index: 3000;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: left 0.4s ease-in-out;
  & > :global(.container-fluid),
  & > :global(.container-fluid) > :global(.row) {
    --bs-gutter-x: 0;
  }
  &Trigger {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -100%) rotate(-90deg);
    transform-origin: bottom;
    z-index: 300;
    font-size: 1.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: left 0.4s ease-in-out, opacity 0.4s ease-in-out;
    &Hidden {
      left: 100px;
      opacity: 0;
    }
  }
  &Visible {
    left: 0;
  }
  &Visible &Trigger {
    left: calc(1 / 12 * 100%);
    @include media-breakpoint-up(md) {
      left: calc(3 / 12 * 100%);
    }
  }
}
