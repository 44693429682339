
@mixin fullWidthCardWithImage {
  position: relative; 
  border: none;
  box-sizing: content-box;
  border-bottom: 2px solid $white !important;
  //hidde items that might be in v1 (of materials):
  .hidden {
    display: none;
  }

  .center1 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 33%;
  }

  .center2 {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 66%;
  }

  h5 {
    @include contentTitle;
    color: white;
    padding: 0;
  }

  p {
    color: white;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.listItem {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  @include fullWidthCardWithImage
}
