.appSideBarItemContainer {
  cursor: pointer;
  // padding-top: 16px;
  padding: 8px 0 8px $side-bar-padding-left;
  .appSideBarItemTitle {
    position: relative;
    > div {
      display: inline-block;
    }
    &:first-child {
      margin-right: 8px;
    }
  }

  .appSideBarItemIcon {
    margin-right: 20px;
    height: 25px;
    width: 25px;
    background: #28A745;
    border-radius: 2px;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
  }

  .appSideBarItemIcon > svg{
    height: 100%;
  }

  .appSideBarCollapseToggle{
    position: absolute;
    right: 20px;
    top: 50%;
    bottom: 50%;
    transform:translateY(-50%);
    color: #D9D6EB;
    fill: #D9D6EB;
  }

  .appSideBarItemChildren {

    ul{
      list-style: none;
      color: rgb(74, 74, 74);;
      line-height: 20px;
      padding: 25px 0 17px 0;
    }
    li{

      .sideBarChildBullet{
        margin-right: 30px;
        padding: 5px 0;
      }
      span{
        display: inline-block;
      }

      @include bp(md) {
        max-width: 225px;
      }
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
