
.profileItemTitle {
  margin-top: 16px;
  // text-transform: none;
  // @include pageTitle;
  // @include addLeadingMargin;
  // @include addTrailingMargin;
  // @include addPadding;
  // color: blue;
}



.profileBig {
  margin-bottom: 128px;
  display: none;
  @include bp(md) {
    display: block;
  }

  min-height: 2000px;
}

.profileSmall {
  margin-bottom: 128px;
  display: block;
  @include bp(md) {
    display: none;
  }
  min-height: 2000px;
}
