.quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
  }
  
  .quote-text {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0px;
   
  }
  
  .quote-author {
    font-style: italic;
    text-align: center;
  }
  
  .mobile .quote-text{
    font-size: 16px;
  }