.specialReportItem {
  @include addTrailingMargin(.33);

  span {
    display: block;
  }
  
  > img {
    width: 100%;
    max-width: 250px;
    height: auto;
    @include addTrailingMargin(2);
  }

  .specialReportsSpacing {
    @include addTrailingMargin;
  }

  > h5 {
    @include subTitle;
    text-transform: none;
  }
}
