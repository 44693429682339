.wlcVideoContainer {
  @include whiteBoxWithShadow;
  padding: 35px 20px;

  h4{
    @include font-light;
    margin-bottom: 2.0rem;
  }
}

/* Rectangle 2: */
