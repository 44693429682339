.markDownContainer {
  @include addLeadingMargin;
  @include addTrailingMargin;

  h1 {
    @include pageTitle;
  }

  h3 {
    @include contentTitle;
  }

  h5 {
    @include subTitle;
  }
}
