.editPicContainer {
  text-align: center;
  > div > div {
    margin: 24px auto;
    overflow: hidden;
    canvas {
      // max-height: 390px !important;
      // max-width: 295px !important;
    }
  }
  .editPicPreviewCroppedImage {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.editPicControls{
  text-align: center;
}
.editPicDropZone{
  border: 3px solid transparent;
  width: 100%;
  text-align: center;
  &Active{
    border: 3px solid $success;
  }
  &Placeholder{
    padding: 100px 0;
    &Title{
      font-size: $font-size-2xlarge;
    }
    &Or{
      margin: 10px 0;
    }
  }
}
