.editUserInfoContainer {
  padding: 16px;
  // @include addTrailingMargin;
  // // @include addLeadingMargin;
  // margin-top: 0;
  // height: 200px;
  &.-onboard {
    padding: 8px;
  }
  .editUserInfoButtons {
    margin-top: 24px;
    text-align: center;
    button {
      @include profileButton;
    }
  }
  .editUserPicButton {
    margin-top: 24px;
    min-height: 64px;
    display: flex;
    justify-content: space-between;
    
    button {
      @include profileButton;
    } 
  }
}

.editUserInfo {
  min-height: 200px;
}

.editUserInfoFormInput {
  @include profileInput;
}

.editUserPicModalContainer {
  padding: 16px;

  .editUserPicModalButton {
    display: flex;
    justify-content: space-around;
    button {
      @include profileButton;
    }
  }
}

.isOnboardEditUserTitle {
  border-bottom: 2px solid #f5f7fa;
  padding-bottom: 12px;
  margin-bottom: 16px;
  span {
    @include font-light;
    font-size: 20px;
  }
}
