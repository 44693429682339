.profileSummary{
  padding-top: 64px;
  padding-bottom: 30px;

  .summaryImgInfo {
    align-items: center;
  }

  .summaryInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    @include bp(md) {
      margin-top: 0;
    }

    .summaryLine {
      &.profileName {
        font-size: $font-size-2xlarge;
      }
    }
  }
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  &Social{
    a:first-child{
      margin-right: 5px;
    }
  }
  &Buttons{
    button{

      margin-right: 5px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 1.0rem;
      }
    }
  }
  &Goal{
    border-top: 1px solid $grey-blue;
    padding-top: 15px;
  }
}

.userAvatar {
  text-align: center;
  margin: 0 auto;
  background-color: $grey-blue;
  //border: 4px solid $white;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius:50%;
  position: relative;
  width: 150px;
  height: 150px;
  &200{
    width: 200px;
    height: 200px;
  }
  img{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 100%;
  }
  span{
    background: rgba($black, 0.50);
    bottom: 0;
    color: $white;
    font-size: $font-size-small;
    padding: 0.9rem 0;
    line-height: $font-size-small;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
