.logo {
    &-ecf {
      max-height: 90px;
    }
  
    &-frn {
      max-height: 75px;
    }
  
    &-footer {
      &:nth-child(odd) {
        margin-right: 2em;
      }
    }
  }
  
  .nav-footer {
    .list-inline-item{
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
  