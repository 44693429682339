.recipesGridContainer {
  .col, [class*="col-"] {
      margin-bottom: 3.0rem;
  }
  .recipesGridBig {
    display: none;
    @include bp(lg) {
      display: flex;
    }
  }
  .recipesGridSmall {
    display: flex;
    @include bp(lg) {
      display: none;
    }
  }
}
