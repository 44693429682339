.weekContainer {
  .weekChecklistAndResources{
    @include media-breakpoint-down(xs) {
      padding-left: 0;
      padding-right: 0;
      .row{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .wlcVideoContainer, .weekRecipesList, .weekChecklistAndResources{
    margin-bottom: 5.0rem;
  }
  .weekChecklistAndResources{
    padding-left: 0;
    padding-right: 0;
  }

  .weekRenderOnboardNav {
    position: absolute;
    z-index: 999;

    span {
      font-size: 16px;
      @include font-light;
      cursor: pointer;
    }
    // .weekRenderOnboardNavTitle {
    //   width: 100%;
    //   text-align: center;
    //   span {
    //     @include font-reg;
    //     font-size: 22px;
    //   }
    // }
    .weekRenderOnboardNavCol {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      span {
        color: $FRNGreen;
        text-decoration: underline;
      }
    }
  }
  .weekRenderOnboardCompleteButton {
    text-align: center;
    button {
      margin-right: 16px;
      @include profileButton;
    }
  }
}

.weekRenderOnboardSuccessModal {
  padding: 16px;

  span {
    font-size: 16px;
  }
  .weekRenderOnboardSuccessModalButtons {
    display: flex;
    justify-content: space-around;
    button {
      @include profileButton;
    }
  }
}


  .weekRenderOnboardNavContainer {
    margin-top: 16px;
    z-index: 999;

    span {
      font-size: 16px;
      @include font-light;
      cursor: pointer;
      color: $FRNGreen;
      text-decoration: underline;
    }
    // .weekRenderOnboardNavTitle {
    //   width: 100%;
    //   text-align: center;
    //   span {
    //     @include font-reg;
    //     font-size: 22px;
    //   }
    // }
    // .weekRenderOnboardNavCol {
    //   padding: 16px;
    //   display: flex;
    //   justify-content: space-between;
    //   span {
    //   }
    // }
  }
.weekRenderOnboardAlert{
  margin-top: 48px;
}
