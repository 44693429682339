@mixin user-recipe($isBig) {
  width: if($isBig, 250px, 116px);
  margin: 0 6px 12px 6px;
  .card-img-top {
    width: if($isBig, 250px, 116px);
    height: if($isBig, 160px, 116px);
    cursor: pointer;
  }
  .card-text {
    padding: 12px 6px 6px 12px;
    font-size: if($isBig, 18px, 12px);
    // prep time and stars
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: grey;
      }

      [class|="star"]{
        width: if($isBig, 16px, 10px);
        height: if($isBig, 16px, 10px);
        cursor: pointer;
        background: url('../images/star/star-filled.png') 0 0px no-repeat;
        background-size: contain;
        &[class$="-unfilled"]{
          width: if($isBig, 16px, 10px);
          height: if($isBig, 16px, 10px);
          background-size: contain !important;
          background: url('../images/star/star-unfilled.png') 0 0px no-repeat;
        }
      }
    }
  }
  > div {
    position: relative;
    .absolute-positioned {
      @include font-light;
      text-align: center;
      position: absolute;
      font-size: if($isBig, 18px, 10px);
      background-color: $FRNPink;
      border-radius: 50%;
      width: if($isBig, 24px, 14px);
      height: if($isBig, 24px, 14px);
      bottom: if($isBig, 10px, 6px);
      right: if($isBig, 10px, 6px);
      color: white;
      cursor: pointer;
    }
  }
}


.card {
  background-color: $white-01;
  border: none;
  color: $text-color-body-dark;
  box-shadow: $main-three-way-box-shadow;
  margin-bottom: 1.6rem;
  & &-horizontal{
    display: flex;
    flex: 1 1 auto;
  }
  &-img{
    &-bg{
      @include border-radius($card-inner-border-radius);
      &, &-top, &-bottom{
        width: 100%;
        padding-bottom: 66.66%;
        background-size: contain;//cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      &-top{
        @include border-top-radius($card-inner-border-radius);
      }
      &-bottom{
        @include border-bottom-radius($card-inner-border-radius);

      }
      &-left{
        flex: 0 0 30%;
        width: 30%;
        padding: 10% 0;
        background-position: center;
        background-size: contain;//cover;
        @include border-top-radius($card-inner-border-radius);
        @include border-bottom-radius($card-inner-border-radius);
      }
    }
    &-align-top{
      .card-img-bg{
        &, &-top, &-bottom{
          background-position: top;
        }
      }
    }
  }

  &-4-3{
    .card-img-bg{
      &, &-top, &-bottom{
        padding-bottom: 75%;
      }
    }
  }

  &-1-1{
    .card-img-bg{
      &, &-top, &-bottom{
        padding-bottom: 100%;
      }
    }
  }

  &-button {
    transition: transform 0.5s cubic-bezier(.165, .84, .44, 1);
    &:hover{
      cursor: pointer;
      transform: scale3d(1.01, 1.01, 1);
      box-shadow: $main-three-way-box-shadow-darker;
    }
  }

  // Deprecate bonus-content-card for content-card?
  &.bonus-content-card {
    .card-body {
      padding-top: 36px;
      padding-bottom: 8px;

      .card-title {
        color: $text-02 !important;
        @include pageTitle;
        @include addTrailingMargin(.75);
      }
      .card-subtitle {
        font-size: $font-size-regular;
        @include addTrailingMargin(.75);
        font-weight: 400;
      }
      .card-text {
        font-size: $font-size-medium;
        line-height: 1.5;
        font-weight: 400;
      }
    }
    .likesAndBookmark {
      padding-top: 0;
      display: flex;
      padding-bottom: 1.25rem; //reset to react-strap default
      justify-content: flex-end;
      > div {
        cursor: pointer;
        padding-left: 16px;
        font-size: 32px; // Needs adjusting
        &.make-me-pink {
          color: $FRNPink;
        }
        &.make-me-black {
          color: $text-01;
        }
      }
    }
  }

  &-content {
    font-size: $font-size-medium;
    .card-title{
      font-size: $font-size-medium;
      font-weight: 600;
      color: $text-01;
      a{
        color: $text-01;
        :hover{
          text-decoration: none;
        }
      }
    }

    .card-title, .card-img-bg-top, .card-img-bg-bottom, .card-img-top,
    .card-img-bottom, .card-subtitle{
      cursor: pointer;
    }
    .card-footer{
      border-top: none;
      background: unset;
    }
  }

  &-dashboard-tile{
    text-align: center;
    cursor: pointer;
    margin: 0;
    .card-img{
      &-bg{
        padding-bottom: calc(130 / 165) * 100%;
      }
      &-overlay{
        background: rgba(0,0,0, 0.5);
      }
    }
    .card-title{
      color: $white;
    }

  }

  &-socials{
    text-align: right;
    > svg {
      cursor: pointer;
    }
  }

  &.product-card {
    display: hidden;
    .card-body {
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;
      display: flex;
      justify-content: left;

      .product-card-img {
        height: 68px;
        width: 68px;
        min-width: 68px;
        margin-right: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include print {
          height: 0;
          display: none;
        }
      }
    }
  }

  &.user-product {
    .card-body {
      display: flex;
      align-items: center;
      color: $text-02;
      .card-img-top {
        width: 125px;
        height: 125px;
      }
      > div {
        margin-left: 1.25rem;
      }
      .card-text {
        font-size: $font-size-regular;
      }
    }
  }

  &.user-recipe {
    @include user-recipe(false);
  }

  &.user-recipe-big {
    @include user-recipe(true);
  }

  &-recipe {
    .card-img-bg-top, .card-title{
      cursor: pointer;
    }
    .card-footer{
      background: $white;
      border-top: none;
    }
    &-rating{
      display: flex;
      align-items: center;
      justify-content: center;
      > svg{
        width: 20% !important;
        color: #FFC107;
        cursor: pointer;
      }
    }
  }

  &.audio-container {
    background: $white-02;
    // these styles are in the local styles.scss file
  }
}
