.audioContainer {
  @include addPadding;
  @include addTrailingMargin;
  audio {
    display: block;
  }
  &:last-of-type {
    @include addTrailingMargin(.33);
  }
}
