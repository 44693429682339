.SurveyPagedContainer{
  .progress{
    height: 2.4rem;
    margin-bottom: 1.5rem;
    &-bar{
      background-color: #007bff !important;
      background-image: unset;
      font-size: 1rem;
    }
  }
}
.SurveyPagedButton{
  &Row{
    justify-content: center;
  }
  &Col:not(:only-child){
    max-width: 20rem;
  }
}
