.cancelModalContainer {
  span {
    @include font-light;
  }
  padding: 16px;
  // @include addTrailingMargin;
  // // @include addLeadingMargin;
  // margin-top: 0;
  // height: 200px;
  // .editUserInfoButtons {
  //   margin-top: 24px;
  //   text-align: center;
  //   button {
  //     @include profileButton;
  //   }
  // }
  // .cancelModalUserPaymentMethod {
  //   padding: 16px;
  //   margin-bottom: 16px;
  // }
  .cancelModalPrompt {
    margin-bottom: 16px;
  }

  .cancelModalButtons {
    button {
      margin-right: 16px;
      @include profileButton;
    }
  }
}
