// @import 'styles/_variables.scss';
// @import '~bootstrap/scss/mixins/_breakpoints';

.activelyPlayingVideo
{
  background:$FRNLightGray;
}
.playListContainer{
  // border:thin solid $FRNLightGray;
  padding-left:0px;
  margin-bottom:15px;
//   @include media-breakpoint-up(lg){
//     border:none;
// }

  border: 1px solid #e8e8e8;
  border-radius: .25rem;
  max-height: 600px;
  overflow: scroll;
  position: relative;
}

.playListVideo{
  padding: 5px 0;
  line-height: 1.25;
  cursor: pointer;
  &-index{
    text-align:center;
    font-size: $font-size-sm;
    color:$FRNGreyLight;
    width: 7%; 
  }
  &-title{   
    @include font-bold;
    font-size: $font-size-sm;
    text-overflow: ellipsis;
    padding: 0.75em;
    width: 65%
  }
  &-thumbnail{ width: 32%; 
   img{
     max-width: 100%;
     height: auto;
   }
  }
  &-subtitle{
    font-size: $font-size-sm * .7;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height:20px;
    color:$FRNGreyLight;
  }
}
