.interviewCollectionWrapper {
    margin-top:15px;
    margin-bottom:30px;
    padding:10px;
   
}

.IC{
    &-bio{
       
        padding:0px;
        position: relative; 
        &:hover{
            text-decoration: none;
        }
        &:first-of-type{
        border-top-left-radius: 15px;
        img{
                border-top-left-radius:15px;
            }
    } 
     &:last-of-type{
            border-top-right-radius:15px;
            img{
                border-top-right-radius:15px;
            }
        }
    img {
        width:100%;
        height:100%;
        margin:0px;
        padding:0px;
        
       
    }
    }
    &-heading{
    font-size:28px;
    color:$FRNDarkPurple;
    font-weight: bold;
    
    }

    &-link{
    margin-top:20px;
    }
    &-bioname {
  
        background:green;
        height:80px;
        padding:2px;
        position: absolute;
        margin-top:-80px;
        font-size:14px;
        width:100%;
        color:white;
        opacity:.8;
        text-align: center;
        overflow: hidden;
        line-height: 20px;
        &:hover{
        font-weight: bold;
       

    }  
}

 &-vertical-center{
       
        height:40px;
        margin-top:20px;
    }


&-nav{
    padding:0px;
    
    border-bottom:thin solid grey;
    height:30px;
    margin-left:0px;
   &:after{
       clear:both;
   }
}
}
.IC-nav li{
    float:left;
    margin-left:20px;
    list-style-type: none;
    padding:5px;
    &:first-of-type{
        margin-left:5px;
    }
}
.IC-nav ul{
    margin-left:0px;
    padding:0px;
}


