.destroyPadding {
  @include noPadding;
}

.bonusPageTitle {
  @include pageTitle;
  @include addLeadingMargin;
  @include addTrailingMargin;
  @include addPadding;
}

.bonusesList {
  @include addTrailingMargin(.3);
  .card-title{
    font-size: $font-size-xlarge;
  }
}
