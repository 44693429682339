.orderListItemContainer {
  line-height: 1em;
  margin-bottom: 16px;
  padding: 16px 16px 16px 16px;

  .orderListSummaryRow {
    display: flex;
    justify-content: space-between;

    .orderListOrderPlaced {
      text-align: left;
      max-width: 50%;
      // flex-basis: 50% !important;
      order: -10;
      @include bp(sm) {
        flex-basis: unset;
        order: unset;
      }
    }

    .orderListOrderTotal {
      margin-top: 16px;
      text-align: right;
      order: 30;
      @include bp(sm) {
        text-align: left;
        margin-top: 0;
        // margin-top: unset;
        // text-align: unset;
        order: 0; // Move me to the end
      }
    }

    .orderListOrderNum {
      text-align: right;
      flex-basis: 50% !important;
      order: -1;
      @include bp(sm) {
        flex-basis: unset;
        order: unset;
      }
    }

    .orderListOrderName {
      margin-top: 16px;
    }
  }

  .orderListItemOrderName {
    cursor: pointer;
    span {
      color: $FRNGreen;
      font-size: 18px;
      text-decoration: underline;
      @include font-reg;
      @include print {
        color: black;
        text-decoration: none;
      }
    }
  }
  .orderListOrderItemProductName {
    margin-bottom: 4px;
    padding-left: 4px;
  }

  .orderListOrderViewDetails {
    margin-top: 16px;
    @include print {
      display: none;
    }
    // span {
    //   color: $FRNGreen;
    //   cursor: pointer;
    // }
  }

  @include print {
    border-bottom: 1px solid black;
    &:last-of-type {
      border-bottom: none;
    }
    body {
      color: black;
    }
  }
}
