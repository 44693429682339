.wlcOnboardCommunityGuidelines{
  h4{
    margin-bottom: 2.0rem;
    text-align: center;
  }
  h5{
    margin-top: 2.0rem;
    text-decoration: underline;
  }
}
