.recipesListContainer {
  .recipesListTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    span {
      @include font-light;
      font-size: 20px;
    }
    a {
      text-decoration: none;
    }
  }

}
.recipesListRecipes{
  & > .col, & > [class*="col-"] {
    margin-bottom: $grid-gutter-width;
  }
}
