.surveyContainer {
  @include addTrailingMargin;
}

.surveyCompleted {
  // @include addTrailingMargin(2);
  // padding-left: $generic-left-margin;
}

.surveyTitle {
  span {
    font-size: $font-size-xlarge;
    @include font-light;
  }
  @include addTrailingMargin;
  padding-bottom: 12px;
  border-bottom: 2px solid #f5f7fa;
}

.surveyHeadline {
  @include contentTitle;
  @include addTrailingMargin(2);
  width: 100%;
  text-decoration: none;
}

.surveyDescription {
  @include addTrailingMargin;
}

.surveyButtonCol {
  text-align: center;
}

.surveyButton {
  @include addTrailingMargin(2);
  @include addLeadingMargin;
}
