.listItem {
  @include addTrailingMargin;
  @include addLeadingMargin;
  border: none;
  .collectionListItemContentTitle {
    @include addPadding;
    @include addTrailingMargin(2);
    @include contentTitle;
  }
}
