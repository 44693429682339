.text {
  // weights
  &400 { font-weight: 400 !important; }
  &600 { font-weight: 600 !important; }
  &700 { font-weight: 700 !important; }

  // styles
  &Uppercase { text-transform: uppercase; }
  &Italic{ font-style: italic;}
  &Underline{ text-decoration: underline;}
  &Capitalize{ text-transform: capitalize; }
  &Strike {text-decoration: line-through; }

  // sizes
  &2Xs  { font-size: $font-size-2xsmall !important; line-height: 1.25em !important;}
  &Xs   { font-size: $font-size-xsmall !important; line-height: 1.25em !important; }
  &Sm   { font-size: $font-size-small !important; line-height: 1.25em !important; }
  &Md   { font-size: $font-size-medium !important; line-height: 1.25em !important; }
  &Base { font-size: $font-size-base !important; line-height: 1.25em !important; }
  &Lg   { font-size: ceil(($font-size-h4 * .75)) !important; line-height: 1.25em !important;
          @media (min-width: $sm-min) {
            font-size: $font-size-h4 !important;
          }
        }
  &LgMd { font-size: $font-size-lg-md !important; line-height: 1.25em !important; }
  &LgXl { font-size: $font-size-lg-xl !important; line-height: 1.25em !important; }
  &Xl   { font-size: ceil(($font-size-h3 * .75)) !important; line-height: 1.25em !important;
          @media (min-width: $sm-min) {
            font-size: $font-size-h3 !important;
          }
        }
  &2Xl  { font-size: $font-size-h2 !important; line-height: 1.25em !important;
           @media (max-width: $xs-max) {
             font-size: ceil(($font-size-h2 * .65)) !important;
           }
        }
  &3Xl  { font-size: $font-size-h1 !important; line-height: 1.25em !important;
          @media (max-width: $xs-max) {
           font-size: ceil(($font-size-h1 * .75)) !important;
          }
        }
  &4Xl  { font-size: $font-size-4xl !important; line-height: 1.25em !important; }
  &5Xl  { font-size: $font-size-5xl !important; line-height: 1.25em !important; }
  &6Xl  { font-size: $font-size-6xl !important; line-height: 1.25em !important; }
}

.greyText {
  color: #444 !important;
}

.whiteText {
  color: $white !important;
}

.greenText {
  color: $FRNGreen !important;
}

.orangeText{
  color: $FRNOrange !important;
}

.centerBlock{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.centerText {
  text-align: center;
}

.bgLightGreen{
  background-color: $FRNLightGreenBackground !important;
}

.bgLightGrey{
  background-color: $FRNLightGreyBackground !important;
}

.bgGrey{
  background-color: $FRNGreyBackground !important;
}
.bgMedGrey{
  background-color: $FRNMediumGreyBackground !important;
}

.bgPurple{
  background-color: #6C028A !important;
}
.bgDarkGrey{
  background-color: $FRNDarkGreyBackground !important;
}

.bgWhite{
  background-color: $white !important;
}

.bgInk{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-ink-gray.png");
  padding: 10px 0 !important;
}

.imgResponsive {
  display: block;
  max-width: 75%;
  height: auto;
}

.sectionHeader {
  padding: 2em !important;
  text-align: center;
  margin-bottom: 24px !important;
}

.banner, .bannerGreen {
  color: $white;
  position: relative;
  text-align: center;
  padding: 3.2rem 0  !important;
  margin-bottom: 50px !important;
  &::after {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    clear: both;
    content: '';
    height: 0;
    position: absolute;
    top: 100%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    width: 0;
    z-index: 999;
  }
}

.banner {
  background-color: $FRNDarkBlueGreyBackground;
  &::after{
    border-top: 30px solid $FRNDarkBlueGreyBackground;
  }
}

.bannerGreen {
  background-color: $FRNGreen;
  &::after{
    border-top: 30px solid $FRNGreen;
  }
}

.bannerHeader {
  margin-bottom: 0 !important;
}

.listStyles {
  .check{
    width: 65px;
    height: 65px;
    margin: 0 auto;
    &Container{
      display: flex;
      align-items: center;
      @media (max-width: $xs-max){
        margin-top: 1.56em;
      }
    }
  }
  .textStyles {
    text-align: center;
    @media (min-width: $sm-min){
      text-align: left;
    }
  }
}
.congrats {
  color: $white;
  font-weight: 700;
  &T{
    line-height: 2.8rem !important;
    font-size: 2em !important;
    @media (min-width: $sm-min){
      font-size: 3em !important;
      line-height: 4.8rem !important;
    }
  }
}

.opportunity {
  color: $white;
  font-size: 1.25em;
  @media (min-width: $sm-min){
    font-size: 1.5em;
  }
  margin-top: 0;
}

.oneTime {
  color: $white;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 3em !important;
  margin: 1.6rem 0 !important;
  line-height: 1.25em !important;
}

.fixedBackground {
  background-image: url('https://cdn.foodrevolution.org/checkout/assets/images/bg-header-welcome.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 0 20px 0;
}

.limitHeight {
  max-height: 750px;
}

.beforeButtonT{
  font-size: 1.2em !important;
  margin-top: 1em !important;
  text-align: center;
  @media (min-width: $sm-min){
    font-size: 1.75em !important;
  }
}

.afterButtonT{
  margin: 1em 0 2em 0 !important;
  text-align: center;
}

.courseContainer{
  background: #FFF;
  border: 4px solid #EEE;
  padding: 22px 12px;

  @media (min-width: $md-min){
    &:first-child{
      margin-right: 10px;
    }
    &:last-child{
      margin-left: 10px;
    }
  }
}

.videoContainer {
  max-width: 900px;
  margin: 0 auto;
  &Inner{
    position:relative;
    height:0;
    padding-bottom:56.25%;
  }
}

.video {
  position:absolute;
  width:100%;
  height:100%;
  left:0
}

.bonusStyles {
  min-height: 400px;
}

.bonusTitle {
  font-size: 24px;
}

.bonusImage{
  max-height: 400px;
}

.sectionLifeEasier{
  background: url('https://cdn.foodrevolution.org/checkout/assets/images/bg-healthy-foods-wood-tray.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.communityContainer{
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 42.5%,rgba(255,255,255,1) 100%), url("https://cdn.foodrevolution.org/checkout/upsells/uhc18/community-plants.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
  @media (max-width: $sm-max){
    background: linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 100%), url("https://cdn.foodrevolution.org/checkout/upsells/uhc18/community-plants.jpg");
    background-size: cover;
    background-position: center;
  }
  &Inner{
    width: 65%;
    padding-left: 35%;
    @media (max-width: $sm-max){
      width: unset;
      padding-left: unset;
    }
  }
}

.communityText{
  @media (max-width: $sm-max){
    color: #FFF !important;
    text-align: center;
  }
}

.miniBanner {
  background-color: $FRNGreyLight;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  font-size: 1.4em;
  text-align: center;
  padding: 3.2rem 0 !important;
  @media (min-width: $sm-min){
    font-size: 2em;
  }
  &WelcomeT{
    font-weight: 700 !important;
    line-height: 1.25em !important;
  }
}

.lead2T{
  font-size: 1.25em !important;
  color: $FRNGrey !important;
  text-align: center;
  margin: 1em 0 !important;
  @media (min-width: $sm-min){
    font-size: 1.75em !important;
  }
}

.leadMainT {
  color: $FRNGrey !important;
  font-size: 1.75em !important;
  line-height: 1.25 !important;
  text-align: center;
  margin-top: 0 !important;
  text-transform: uppercase;
  font-weight: 700 !important;
  @media (min-width: $sm-min){
    font-size: 3em !important;
  }
}

.title {
  font-size: 3.2rem;
  font-weight: 700;
  background: url('https://cdn.foodrevolution.org/checkout/assets/images/bg-ink-gray.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px 0;
}

.titleT {
  text-align: center;
}

.speakersBackground {
  width: 75%;
}

.speakers {
  background-color: $white;
  padding-bottom: 30px;
  position: relative;
  overflow:hidden;
  z-index: 999;
}

.speakerColumn {
  margin-top: 24px;
  max-width: 1280px;
}

.columnSubTitle {
  font-size: 3rem;
}

.columnSubTitleT {
  text-align: center
}

.speaker {
  width: 375px;
  z-index: 999;
}

.speakerTime {
  background: #6e328a none repeat scroll 0 0;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  left: -10px;
  line-height: 1em;
  padding: 1em 0;
  position: absolute;
  text-align: center;
  top: -10px;
  vertical-align: top;
  width: 50px;
}

.imageContainer {
  position: relative;
  margin-bottom: 16px;
  max-height: 360px;
  width: 95%;
}

.image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.speakerName {
  bottom: 0;
  color: $white;
  font-size: 1.6rem;
  font-weight: 700;
  left: 0;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  right: 0;
  @media (min-width: $sm-min){
      font-size: 1.28rem;
  }
  @media (min-width: $md-min){
      font-size: 1.6rem;
  }
}

.limitedTime {
  font-size: 4rem;
  font-weight: 500;
  color: $FRNGreen;
}

.limitedTimeT {
  font-size: 4rem;
  margin-top: 24px;
  text-align: center;
}

.upsellButton {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;

  &Decline{
    min-height: 40px;
    margin-top: 0.5em;
  }
}


.footer {
  background-color: $FRNDarkGreyBackground;
  margin-bottom: -8px !important;
}

.footerText {
  color: white;
  font-size: 1.9rem;
  font-weight: 400;
  margin-left: 8px;
  text-decoration: none;
}

.footerTextT {
  padding-top: 0;
  padding-bottom: 0;
}

.footerPaddingTop {
  margin-left: 0;
}

.footerLinks{
  display: block;
  text-align: center;
  @media (min-width: $sm-min){
    float: right;
    &:after{
      content: ' ';
      float: none;
      clear: both;
      display: block;
    }
  }
}

.speaker{

  &Image{
    img{
      max-height: 265px;
      max-width: 265px;
      border-radius: 10px;
      width: 95%;
    }
  }

  &Details{
    color: $FRNGreyLight;
    margin-top: 1em;
    text-align: center;
  }
}
.speakersBackground::before {
  background-image: url('https://cdn.foodrevolution.org/checkout/assets/images/bg-purple-onion.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  opacity: .25;
  position: absolute;
  top: 45%;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  width: 50%;
  z-index: -1;

  @media (min-width: 1800px){
    opacity: 1;
  }

  @media (min-width: $md-min){
    width: 30%;
  }
}

.speakersBackground::after {
  background-image: url('https://cdn.foodrevolution.org/checkout/assets/images/bg-spinach-bowl.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  content: '';
  display: block;
  height: 100%;
  opacity: .25;
  position: absolute;
  right: 0;
  top: 10%;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  width: 50%;
  z-index: -1;

  @media (min-width: 1800px){
    opacity: 1;
  }

  @media (min-width: $md-min){
    width: 30%;
  }
}


.upsellContainer {
    max-width: 1280px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: $sm-min){
        width: 750px !important;
    }
    @media (min-width: $md-min){
        width: 970px !important;
    }
    @media (min-width: $lg-min){
        width: 1170px !important;
    }
}

.marginBottom0{
  margin-bottom: 0 !important;
}

@media (max-width: $xs-max){
  .courseContentFooter{
    text-align: center;
  }
}
