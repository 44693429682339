.audioPlayerMobileTextContainer {
  margin-top: 1.5rem;
  text-align: left;

  > div {
    margin-bottom: 8px;
  }
  span {
    font-size: $font-size-medium;
  }
}
.apmPictureContainer{
  max-width: 150px;
  
}

.apmPictureContainerV2{
  
}
