.audioPlayerMobileControlsContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 2.0rem;
  color: $grey-blue-dark;
  > div {
    line-height: 3;
    cursor: pointer;
  }
  .apmcButton {
    padding-top: 1px;
    margin: 0 auto;
    background-color: $FRNPink;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    text-align: center;
    line-height: 30px;
    svg {
      color: white !important;

    }
  }
}
