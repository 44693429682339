  // FIX make me a mixin

@mixin star($isBig) {
  [class|="star"]{
    // Fix use larger font icon instead of image
    // transform: if($isBig, scale(1.5), 0);
    width: 16px;
    height: 16px;
    margin: 0 2px;
    cursor: pointer;
    background: url('../images/star/star-filled.png') 0 0px no-repeat;
    background-size: contain;
    &[class$="-unfilled"]{
      background-size: contain !important;
      background: url('../images/star/star-unfilled.png') 0 0px no-repeat;
    }
  }
}

.recipeStarsContainer {
  display: flex;
  margin-bottom: 12px;
  justify-content: center;
  font-size: 2.4rem;
  @include star(false);
  @include bp(lg) {
    margin-bottom: 16px;
    justify-content: flex-end;
    @include star(true);
  }
  svg {
    cursor: pointer;
  }
}
