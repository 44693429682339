.audio-container{
  .progressBarContainer{
    display: flex;
    align-items: center;
    color: $grey-blue-dark;
  }

  .progress {
    background-color: $white-01;
    border: 1px solid $white-03;
    cursor: pointer;
    > div {
      background-color: transparent !important;
      background-image: linear-gradient(to right, $gradient-1-end, $gradient-1-start);
    }
    .progress-time {
      padding: 0 8px;
    }

  }
}
