.flexCenter{
  display:flex;
  align-items: center;
  justify-content: center;
}

.navbar .align-middle{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Rectangle: */
.appHeaderContainer {
  z-index: 999999;
  position: fixed;
  top: 0;
  width: 100%;
  transition: padding-left .4s ease-in-out;
  transition: margin-left .4s ease-in-out;
  display: flex;
  align-items: center;
  color: white;
  height: 73px;
  padding:0px;
  justify-content: space-between;
 
  background:$FRNPurple;
  .modal-open &{
    z-index: 999 !important;
  }
}
  
  
  .appNavBarBrand {
    @include font-light;
    color: white;
  }

  .appNavBarBarIcon {
    path {
      color: white;
    }
    font-size: 24px;
    cursor: pointer;
    margin-left: 24px;
  }


.headerColumn {
  text-align: center;
}



.colorize-pink {
  filter: brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(5);
}


