.orderConfirmationTop {
  margin-top: 16px;
}

.orderConfirmationMargin {
  margin-left: 16px;
  margin-right: 16px;
}

.orderProcessed {
  margin-right: 0;
}

.myProductsButton {
  width: 100%;
  color: grey;
}

.myProductsButton:hover {
  cursor: pointer;
}

.grey {
  background-color: lightgrey;
}

.bold {
  font-weight: 700;
}

nameWidth {
  width: 75%;
}

priceWidth {
  margin-left: 8px;
}
