.addressesContainer{
  span, h5 {
    @include font-light;
  }
}

.addressesAddNewButton {
  margin-top: 24px;
  button {
    @include profileButton;
  }
}

.addressAddNewButtonBox {
  margin: 24px 0;
  text-align: center;
  button {
    @include profileButton;
  }
}