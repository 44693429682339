.o-nav-buttons{
    display:flex;
  }
  .o-nav-buttons button{
    font-size:30px;
  }

  .o-step-foooter{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:20px;
  }