.video {
  &-wrapper {
    position: relative;
    padding-top: 56.25%;
    z-index: 10;
  }

  &-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
