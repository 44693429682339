.ICCircleImage{
  width: 45px;
  height:45px;
  
}
.ICCircleImage img{
    width:45px;
    border-radius: 50%;
}

.InterviewCategory:hover{
  cursor: pointer;
}

.InterviewCategorySpeakerName:hover{
  text-decoration: underline;
}
