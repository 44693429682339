.form-control {
  // class login-input is applied to the reactstrap component
  &.login-input {
    color: $text-color-body;
    font-weight: 100;
    border-color: $text-03;
    // text is centered from within the react element
    line-height: 2;
    font-weight: 100 !important;
    background-color: $field-01;
    border-radius: 6px;
    margin-bottom: 14px;
    outline: none !important;
    // &:empty {
    //   text-align: center;
    // }
    &::placeholder {
      color: $text-03;
      text-align: center;
    }
    &:focus {
      outline: none;
    }
  }
}

input[type=checkbox] {
  & + label {
    height: 24px;
    width: 24px;
    background-size: contain !important;
    padding: 0 0 0 32px;
    margin-right: 6px;
    cursor: pointer;
    color: transparent;
    font-size: .1rem;
  }
}

// input[type=checkbox]:checked + label {
//   background: url('../images/checkbox/checkbox-checked.png') 0 0px no-repeat;
// }
