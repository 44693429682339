// body {
//   margin: 0;
//   background-color: $white-01;
//   @include bp(md) {
//     background-color: $white-02;
//   }
// }

ul {
  margin-bottom: 0;
}

.removePadding {
  padding: 0 !important;
  // @include noPadding;
}
