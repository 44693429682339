.resetFormContainer {
  .resetFormBack {
    @include addLeadingMargin;
    @include addTrailingMargin;
    text-align: center;
    span {
      color: $FRNLightBlue;
      // font-size: $font-size-medium;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

