.onboarding  .list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between items */
}

.onboarding .o-list-item {
  display: flex;
  flex-direction: row; /* Change to row */
  align-items: center; /* Align items vertically */
  gap: 20px; /* Add spacing between image and content */

 
}
.onboarding .image
{
  width:65px;
  height:   65px;
  margin-left:3px;
 
}
.onboarding .image img {
  width: 65px;
  height: 65px;
  object-fit: cover;
 

  
}

.onboarding .o-content {
  height:100;
 
  align-items: center;
  text-align: left;
}

.onboarding .o-title {
  justify-self: flex-start;
  font-size: 2rem;
  margin:0;
}

.onboarding .o-description {
  justify-self: flex-start;
}
.o-not-selected
{
  border:3px solid #e6e6e6;
  border-radius: 20px;
}
.o-selected
{
  /* background-color: #e6e6e6; */
  border:3px solid #048D22;
  border-radius: 20px;
}
/* media query for mobile */
@media screen and (max-width: 768px) {
  .onboarding .list {
    flex-direction: column;
    margin-left:0;
    margin-right:0;
  }
  .onboarding .o-list-item {
    flex-direction: column;
    gap: 10px;
  }
  .onboarding .o-content {
    text-align: center;
    padding:5px;
    padding-top:0px;
    
  }
  .onboarding .o-title {
    justify-self: center;
  }
  .onboarding .o-description {
    justify-self: center;
  }
}