.wlcWeekNavContainer {
  margin-top: 24px;
  z-index: 998;
  position: relative;
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  // z-index: 3000;
}

.wlcWeekOf{
  text-align: right;
  @include media-breakpoint-down(sm){
    text-align: left;
  }
}

.wlcWeekNavSelect {
  display: flex;
  justify-content: flex-end;
  > div {
    padding: 12px;
    min-width: 275px;
    height: 120px;
    background-color: $white;
    z-index: 1200;
    max-width: 225px;
    transform: translate(2px, -16px);
    box-shadow: $main-three-way-box-shadow;
    a, span {
      color: $FRNGreen;
    }
  }
  position: relative;
}
