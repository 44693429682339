.articleContainer {
  // background-color: $white-02;

  .articleCopy{
    img{ max-width: 100%;}
  }

  ul{
    padding-bottom: 4.0rem;
  }

  .articleHeaderImage {
    margin-bottom: 36px;
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
  }

  .articleTitleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .articleAuthorContainer {
    display: flex;
    margin: 36px 0;
    > div {
      margin-right: 16px;
    }
    .articleAuthorImage {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
    }
 }
}
