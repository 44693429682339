// .userInfo {
//   min-height: 160px;
// }

.userPersonalInfoContainer {
  @include addTrailingMargin;
  padding: 16px;
  margin-top: 0 !important;
  .userPersonalInfoButton {
    button {
      @include profileButton;
    }
    margin-top: 16px;
  }

  .userPersonalInfoLine {
    margin-bottom: 8px;
    span {
      color: purple;
      @include font-light;
    }
    // @include profileDataItem;
  }
  .userPersonalInfoTitle {
    span {
      @include font-light;
      color: #868E96;
    }
  }
}
