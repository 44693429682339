@import '~bootstrap/scss/_functions.scss';
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.offset {
  &-video-box {

    @include media-breakpoint-up(sm) {
      margin-top: -9em;
    }

    @include media-breakpoint-up(md) {
      margin-top: -8em;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -6em;
    }

    &-during {
      @include media-breakpoint-up(sm) {
        margin-top: -9em;
      }

      @include media-breakpoint-up(md) {
        margin-top: -12em;
      }

      @include media-breakpoint-up(lg) {
        margin-top: -18em;
      }
    }
  }

  &-header {
    @include media-breakpoint-up(sm) {
      padding-bottom: 9em;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 8em;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 6em;
    }

    &-during {
      @include media-breakpoint-up(sm) {
        padding-bottom: 9em;
      }

      @include media-breakpoint-up(md) {
        padding-bottom: 12em;
      }

      @include media-breakpoint-up(lg) {
        padding-bottom: 18em;
      }
    }
  }
}
