.progress-image img
{
    width:40px;
    max-width: 40px;
    cursor: pointer;
}
/* media query for small screens */
@media screen and (max-width: 767px) {
    .progress-image img
    {
        width:30px;
        max-width: 30px;
    }
}