@import url("https://use.typekit.net/ayx6cuo.css");
$font-weight-extrabold: 800;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-light: 300;
@mixin font-reg {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
}

@mixin font-bold {
  @include font-reg;
  font-weight: $font-weight-bold;
}

@mixin font-bold-italics {
  @include font-bold;
  font-style: italic;
}

@mixin font-light-italics{
  font-family: Light It;
}

@mixin font-light {
  @include font-reg;
  font-weight: $font-weight-light;
}

@mixin font-reg-italics{
  @include font-reg;
  font-style: italic;
}

@mixin font-medium {
  @include font-reg;
  font-weight: $font-weight-medium;
}

@mixin text-size-regular{
  font-size: $font-size-regular;
}

@mixin text-size-regular{
  font-size: $font-size-large;
}


// Help!
.font-size-medium {
  font-size: $font-size-medium;
}

.font-size-regular {
  font-size: $font-size-regular;
}

.text-large, .textLarge{
  font-size: $font-size-large;
}

.textCenter{
  text-align: center;
}

.textUppercase{
  text-transform: uppercase;
}


html {
  font-size: 10px;
  
}

body {
  @include font-reg;
  font-size: $font-size-regular;
  color: $text-color-body;
  
}

h1, h2, h3, h4, h5, h6{
  font-weight: $font-weight-extrabold;
  margin-top: $headings-margin-top;
  
  &.light{
    color: $text-color-heading-light;
  }


  .text-white &{
    color: white;
  }

  .pagetitle &{
    margin-top: 0;
    margin-bottom: 0;
    @include media-breakpoint-down(xs) {
      margin-bottom: $font-size-base * 0.5;
    }
  }

  .card-header &{
    margin-top: 0;
    margin-bottom: 0;
  }
  &.card-title{
    margin-top: 0;
    margin-bottom: 0.5em;
  }
  .post-form &{
    margin-top: 0
  }
}

.pagetitle{
  margin-top: $font-size-base;
  margin-bottom: $font-size-base * 1.25;
}


.list-group-item
{
  font-size:$font-size-large;
  // padding-top:1em;
  // padding-bottom:1em;
}
// .list-group-item span{
//   padding-left:.4em;
// }
// .list-group-item:last-child {
//   border-bottom:thin solid $FRNGreyLighter !important;
// }
a.list-group-item{
  color: $FRNGreyDark;
  text-decoration: none;
}
a.list-group-item:hover{
  background:#f8f9fa;
}

.text-800{
  font-weight: 800;
}

.text-medium-gray{
  color: $medium-gray;
}