.updateModalContainer {
  padding: 16px;
  span {
    @include font-light;
  }
  // @include addTrailingMargin;
  // // @include addLeadingMargin;
  // margin-top: 0;
  // height: 200px;
  // .editUserInfoButtons {
  //   margin-top: 24px;
  //   text-align: center;
  //   button {
  //     @include profileButton;
  //   }
  // }
  .updateModalUserPaymentMethod {
    padding: 8px 16px 16px 16px;
    margin-bottom: 16px;
  }
  .updateNewModalButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    button {
      // margin-right: 16px;
      @include profileButton;
    }
  }
  .updateExistingModalButtons {
    button {
      // margin-right: 16px;
      @include profileButton;
    }
  }
}

.updateModalStateContainer {
  padding: 16px;
  .updateModalStateButtons {

    button {
      margin-right: 16px;
      @include profileButton;
    }
  }
}
