.collectionContainer {
  @include noPadding;
  @include addLeadingMargin;
  @include addTrailingMargin;
  max-width: 100vw;
}

.list {
  @include addTrailingMargin(2);
}

.listTitle {
  @include addPadding;
  @include pageTitle;
  @include addTrailingMargin;
}

.subTitle {
  @include subTitle;
}
