
.product-nav{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 0;
    height: 0;
    display: flex;
    flex-direction: row;
  
  &-open{
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.75);
  }

  &-open &-menu{
    left: 0;
    width: 320px;
    opacity: 1;
  }

  &-menu {
    position: fixed;
    top: 0;
    width: 0;
    left: -320px;
    opacity: 0;
    height: 100%;
    background-color: var(--dark);
    color: var(--white);
    transition: all 0.3s;
    z-index: 1000;
    font-size: 2.25rem;
    padding-top: 25px;

    ul{
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    a{
      display: block;
      padding: 8px 16px;
      text-decoration: none;
      color: var(--white);
      &:hover{
        font-weight: bold;
      }
    }
  }

  &-fill{
    display: flex;
    flex: 1;
  }
}