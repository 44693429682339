@mixin recipeContainer($isBig) {
  // background-color: $white-02;
  .recipeImage {
    width: 100%;
    height: if($isBig, 300px, 200px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -99;
    margin-bottom: if($isBig, 0, -63px);
    @media screen {
      // Only displays content on print
      content: none !important;
    }
  }

  .recipeMeta {
    &Title {
      text-transform: uppercase;
      font-weight: 700;
      color: $text-color-heading-muted;
      font-size: $font-size-medium;
    }
    &Value {
      color: $FRNGreyDark;
      font-size: $font-size-medium;
    }
  }
  .recipeServingsArrow {
    color: $text-color-body;
    cursor: pointer;
    margin-left: 5px;
  }

  .recipeMenuBar {
    background-color: $white-01;
    padding: 5.0rem 0;
    box-shadow: $main-three-way-box-shadow;
    margin-bottom: 3.5rem;
    @include media-breakpoint-down(xs) {
      margin-left: 15px;
      margin-right: 15px;
      width: auto;
    }

    .recipeTitleRow {
      width: calc(100%);
      margin-bottom: 16px;
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
      .recipePrintCol {
        // display: if($isBig, flex, none);
        justify-content: flex-end;
        text-align: right;
        font-size: 2.0rem;
        line-height: 1.2;
        > svg {
          cursor: pointer;
        }
        @include media-breakpoint-down(xs) {
          justify-content: center;
          text-align: center;
        }
      }
    }
    .recipeMenuBars {
      margin: 0;
      padding: 0;
      @include media-breakpoint-down(xs) {
        padding: 1.0rem 0;
      }
      .row {
        margin: 0;
        padding: 0;
        > div {
          margin-bottom: 1.5rem;
        }
        > div > div {
          display: inline-block;
        }
      }
      .recipeMenuIcon {
        font-size: 3.5rem;
        margin-right: 1rem;
        color: $text-color-heading-muted;
        @include media-breakpoint-down(xs) {
          font-size: 2.6rem;
        }
      }
      @include media-breakpoint-down(xs) {
        .recipeServingsArrow {
          display: none;
        }
      }
    }
    .recipeMenuStarsAndBars {
      flex-direction: row-reverse;
      > div {
        @include media-breakpoint-down(xs) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    .recipeMenuAllergens {
      &Title {
        margin-bottom: 1.5rem;
      }
      > div {
        display: flex;
        > div {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
          margin-right: if($isBig, 22px, 13px);
          width: if($isBig, 55px, 27px);
          height: if($isBig, 55px, 27px);
        }
      }
      .recipeMenuAllergensRecipePics {
        display: if($isBig, block, none);
        display: flex;
        justify-content: right;
        color: purple;
        > div {
          flex-basis: 33%;
          text-align: right;
          // margin-left:
        }
      }
    }
  }

  
  ul,
  ol {
    margin-bottom: 0;
    padding-left: 16px;
  }

  // No display UNLESS print
  .recipeIngredientPrintable,
  .recipePrintTitle {
    display: none;
  }
  // FOR PRINT ONLY
  @include print {
    font-size: $font-size-large;
    
    .container {
      width: 100%;
      margin: 0;
      padding: 20px;
      //max-width: unset;


    }

    .recipeImage {
      height: auto;
      width: 300px;
      float: right;
    }
    .recipeIngredientPrintable {
      display: block;
      list-style-position: inside;
    }

    .recipePrintTitle {
      display: block;
      font-size: $h2-font-size;
      // margin-left: $grid-gutter-width/2;
      margin-top: 2.0rem;
      margin-bottom: 2.0rem;
    }

    .recipeIngredient,
    .recipePrintCol,
    .recipeTitleRow,
    svg[data-icon="arrow-alt-down"],
    svg[data-icon="arrow-alt-up"] {
      display: none !important;
    }
    .recipeImage {
      margin-left: calc($grid-gutter-width/2);
      margin-bottom: 2.0rem;
    }
    .recipeMenuBar {
      padding: 0;
      .recipeMenuAllergens {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
        color-adjust: exact !important; /*Firefox*/
      }
    }
    .recipeMenuStarsAndBars {
      text-align: left;
      display: block;
      .recipeMenuBars {
        margin-left: calc(-1*$grid-gutter-width/2);
      }
      .recipeStarsContainer {
        justify-content: unset;
      }
      .container,
      .row,
      div[class^="col-"] {
        flex: unset;
        width: auto;
      }
    }
    .recipeDirectionsContainer {
      padding: 0;
      > div {
        padding: 1.0rem 0;
      }
    }
    .recipeIngredientsDirections {
      h4 {
        margin: 1.0rem 0;
      }
      .col-12 {
        display: block;
        width: 100%;
        flex: unset;
        max-width: 100%;
        margin-bottom: 2.4rem !important;

      }
    }


  }
}

.recipeContainer {
  @include recipeContainer(false);
  @include bp(lg) {
    @include recipeContainer(true);
  }
}

.recipeIngredientsDirections {
  h4 {
    margin-bottom: 2.0rem;
  }
}

.recipeIngredient {
  margin-bottom: 0.5rem;
  padding: 1.8rem 0;

  &Container {
  }
  &Amount {
    &Label {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: $font-size-small;
      color: $text-color-body-muted;
    }
    &Value {
    }
  }
  &Img {
    width: 4.5rem;
    height: 4.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &Name {
  }
  &Notes {
    color: $text-color-body-muted;
    font-size: 0.8em;
  }
}
/* COOK mode */

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $FRNGreen;
}

input:checked + .slider:before {
  transform: translateX(26px);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.toggle-container {
  display: flex;
  align-items: center;
  width:100%;
  
  
}
.toggle-container span {
  margin-left: 10px;
}
.toggle-container label{
  margin-bottom: 0;
}
