.audioPlayerMobileHeaderContainer {
  display: flex;
  justify-content: space-between;
  @include addTrailingMargin;

  .apmhOne {
    border-radius: 8px;
    width: 85px;
    height: 85px;
    min-width: 85px;
    min-height: 85px;
    background: lightgrey;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    // background-position: center;
  }

  .apmhTwo {
    width: 100%;
    padding-left: 10px;
    .apmhInterviewee {
      text-align: left;
      margin-top: .75rem;
    }
    line-height: 1.2;
  }

  .apmhThree {
    flex-basis: 20%;
    margin-top: .75rem;
    text-align: right;
    min-width: 90px;
    svg {
      cursor: pointer;
      margin-left: 12px;
      transform: scale(1.33);
    }
    div {
      color: $FRNPink;
    }
  }
  @include media-breakpoint-down(xs){
    .amphFullTitle{
      @include font-reg;
      font-size: $font-size-regular;
    }
  }
}
