$green: #28A745;
$carrot: #fb6d25;
$orange: #ff9400;
$blueskies: #72c9ed;

// Fills
$dark-blue-gray: #37474f;
$high-blue-gray: #4d6f7b;
$blue-gray: #dce0e2;
$light-blue-gray: #f3f4f5;
$medium-blue-gray: #8493a8;
$mint: #f3f8f7;
$deep-mint: #d9eeeb;
$teal: #17a2b8;
$red: #e2515f;

// Secondary/Accents
$purple: #630090;
$lightpurple: #ab08d3;
$mellow: #f8bb1c;

// Generic
$white: #fff;
$black: #000;
$transparent: rgba($white, 0);
$light-gray: #eceff1;

// Social Media Branding
$facebook: #4267b2;
$facebook-hover: #365899;
$twitter: #00aced;
$twitter-hover: #0084b4;
$pinterest: #cb2027;
$pinterest-hover: darken($pinterest, 5%);
$instagram-y: #fdf497;
$instagram-o: #fd5949;
$instagram-m: #d6249f;
$instagram-b: #285aeb;
$instagram-p: #893dc2;
$youtube: #f00;
$youtube-hover: darken($youtube, 5%);

.UpSellAC18{
  background: $white;
  font-size: 2.0rem;
  .background-dark, .background-dark .col{ color: $white; }
  h1,h2,h3,h4,h5,h6{
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: .5em;
    margin-top: 0;
    color: #37474F;
  }
  h5{
    font-size: 2.4rem;
    color: #37474F;
  }

  .text-h5{
    font-size: 2.4rem;
  }
  .text-center{
    text-align: center;
  }
  .container{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

  }
  .has-triangle-bottom .container{
    padding-bottom: 0;
  }

  .btn-wrap{
    white-space: normal;
  }

  .arrow {
    &-bottom {
      margin-bottom: 50px;
      margin-top: -1px;
      position: relative;

      &::after {
        border-left: 50px solid $transparent;
        border-right: 50px solid $transparent;
        clear: both;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translate(-50%, 0);
        width: 0;
        z-index: 1;
      }

      &-green { &::after { border-top: 50px solid $green; } }
      &-teal { &::after { border-top: 50px solid $teal; } }
      &-lightpurple { &::after { border-top: 50px solid $lightpurple; } }
      &-purple { &::after { border-top: 50px solid $purple; } }

      &-black {
        &-25 { &::after { border-top: 50px solid transparentize($black, .75); } }
      }

      &-size {
        &-25 {
          margin-bottom: 25px;

          &::after {
            border-left-width: 25px;
            border-right-width: 25px;
            border-top-width: 25px;
          }
        }

        &-30 {
          margin-bottom: 30px;

          &::after {
            border-left-width: 30px;
            border-right-width: 30px;
            border-top-width: 30px;
          }
        }
      }

      &-margin-fix {
        &-30 { margin-top: -30px; }
      }
    }
  }

  .background {
    position: relative;

    &-overflow { overflow: visible; }

    &-dark { color: $white; h1,h2,h3,h4,h5,h6{ color: $white;} }

    // colors
    &-high-blue-gray { background-color: $high-blue-gray; }
    &-light-blue-gray { background-color: $light-blue-gray; }
    &-green { background-color: $green; }
    &-mint { background-color: $mint; }
    &-deep-mint { background-color: $deep-mint; }
    &-purple { background-color: $purple; }
    &-lightpurple { background-color: $lightpurple; }
    &-white { background-color: $white; }
    &-teal { background-color: $teal; }

    // overlays
    &-overlay {
      position: relative;

      &::before {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }

      &-black { &::before { background-color: transparentize($black, .95);} }
      &-black-90 { &::before { background-color: transparentize($black, .1); } }
      &-black-75 { &::before { background-color: transparentize($black, .25); } }
      &-black-50 { &::before { background-color: transparentize($black, .5); } }
      &-black-25 { &::before { background-color: transparentize($black, .75); } }
      &-white-90 { &::before { background-color: transparentize($white, .1); } }
      &-white-75 { &::before { background-color: transparentize($white, .25); } }
      &-white-50 { &::before { background-color: transparentize($white, .5); } }
      &-white-25 { &::before { background-color: transparentize($white, .75); } }
      &-purple { &::before { background-color: transparentize($purple, .4); }}

      &-xs { &::before { @media (min-width(var(--breakpoint-sm))) { display: none; } } }
      &-sm { &::before { @media (min-width(var(--breakpoint-md))) { display: none; } } }
      &-md { &::before { @media (min-width(var(--breakpoint-lg))) { display: none; } } }

      &-gradient {
        &-purple-x { &::before { background: linear-gradient(transparentize($lightpurple, .2) , transparentize($purple, .2)); } }
      }

      &-angle {
        &::before {
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;

          @media (min-width(var(--breakpoint-sm))) {
            width: 65%;
          }
        }

        &-left {
          &::before {
            @media (min-width(var(--breakpoint-sm))) {
              left: -10%;
              transform: skewX(-15deg);
            }
          }
        }

        &-right {
          &::before {
            @media (min-width(var(--breakpoint-sm))) {
              left: initial;
              right: -10%;
              transform: skewX(15deg);
            }
          }
        }
      }
    }

    // gradients
    &-gradient {
      // colors
      &-purple { background: linear-gradient(to right, $purple, $lightpurple); }
      &-mint-y { background: linear-gradient($white, $mint); }
      &-deep-mint-y {background: linear-gradient($white, $deep-mint); }
      &-deep-mint-yb { background: linear-gradient($deep-mint, $white); }
    }

    // images
    &-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      &-fixed {
        background-attachment: fixed;
      }

      &-overlay-gradient-white-right {
        &::before {
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;

          @media (min-width(var(--breakpoint-md))) {
            background: linear-gradient(to right, $white 50%, $transparent 80%);
          }
        }
      }

      &-fruits {
        background-image: url('https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-section-bg-fruits.jpg');
      }

      &-fruit-veggies {
        background-image: url('https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-bg-fruit-veggies.jpg');
      }

      &-trees-white {
        background-image: url('https://cdn.foodrevolution.org/checkout/upsells/ac18/bg-trees-white.jpg');
        background-position: center bottom;

        &::before {
          background: linear-gradient($white 0, $transparent 10%, $transparent 90%, $white 100%);
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      &-woman-salad{
        background-image: url('https://cdn.foodrevolution.org/checkout/upsells/ac18/wlc-bg-woman-salad.jpg');
        background-position: right bottom;
        background-size: contain;
      }
    }



  }


  .fill {
    &-curve {
      height: 50px;
      overflow: hidden;

      &-top {
        margin-top: -50px;
      }

      &-padding {
        &-bottom { padding-bottom: 50px; }
      }
    }

    &-svg {
      height: 100%;
      width: 100%;
    }

    &-path {
      stroke: none;
    }

    &-mint { fill: $mint; }
    &-purple-gradient { fill: url('#purple-gradient'); }
  }

  .img-trees-future {
    max-height: 115px;
  }
  .center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-orange {
    background-color: $orange;
    color: #fff;
  }
  .btn-grey {
    background-color: #AAA;
    color: #fff;
  }
  .btn-box-shadow {
    box-shadow: 0 2px 10px rgba(0,0,0,.25);
  }
  .what-you-get{
    img{
      max-height: 300px;
    }
  }
  .img-circle {
    border-radius: 50%;
  }
  .img-responsive {
    display: block;
    width: 100%;
    height: auto;
  }
  .section-speakers{
    .speaker-list{
      justify-content: center;

      .col{
        flex: 0 0 50%;
        max-width: 50%;

        @media (min-width: 992px) {
          flex-basis: 25%;
          max-width: 25%;
        }
        @media (min-width: 1200px) {
          flex-basis: 20%;
          max-width: 20%;
        }
        padding-top: 40px;
        padding-bottom: 40px;
        img{
          margin-bottom: 20px;
        }
      }
    }
    @media (max-width: 575px) {
      padding: 0 !important;
      .speaker-list .col{
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
  .background-dark{
    a, a:hover{
      color: $white;
    }
  }
}
