.broadcastsListContainer {
  @include noPadding;
  @include addLeadingMargin;
  @include addTrailingMargin;
}

.broadist {
  @include addTrailingMargin(2);
}

.broadcastListTitle {
  @include addPadding;
  @include pageTitle;
  @include addTrailingMargin;
}

.subTitle {
  @include subTitle;
}
