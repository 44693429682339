.wlcNavContainer {
  background: $white-01;
  @include media-breakpoint-down(sm){
    .nav-item {
      margin-left: 22px;
      cursor: pointer;
    }
    .nav-item.active{
      display: none;
    }
    .navbar, .navbar-light{
      &-brand{
        height: 6.5rem;
        font-size: $font-size-regular;
        color: $blue-02 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
      &-toggler{
        border: none;
      }
    }
  }
  @include media-breakpoint-up(sm){
  .nav{
    &bar{
      padding: 0;
      height: 6.5rem;
      &-brand{
        display: none;
      }
      &-collapse{
        height: 100%;
      }
      &-nav{
        width:100%;
        height: 100%;
      }
    }
    &-item{
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      cursor: pointer;
      &.active{
        border-bottom: 2px solid $blue-02;
      }
    }
    &-link{
      cursor: pointer;;
    }
  }
    .navbar-light .navbar-nav .active > .nav-link{
      color: $blue-02;
    }
  }
  @include print {
    display: none;
  }
}
