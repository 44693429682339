.staff-picks{
/* Center the profile picture and staff name */
.profile-pic {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }
  
  /* Adjust card spacing */
  .card {
    border: none;
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Increase space between staff name and profile pic */
  .card-body .ml-2 {
    margin-left: 8px;
  }
  
  /* Style staff review */
  .card-text {
    height: 80px; /* Limit description height */
    overflow: y-scroll;
    /* scroll */
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

  }
  
  .font-italic {
    color: #888;
  }
  
  .aspect-ratio-16by9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 9/16 * 100% */
  }
  
  .aspect-ratio-16by9 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will ensure the image covers the entire space and maintains aspect ratio */
  }
}