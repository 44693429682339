

// .editUserInfo {
//   min-height: 300px;
// }

.passwordResetContainer {
  span, h5 {
    @include font-light;
  }
  min-height: 48px;
  padding: 16px;

  .passwordResetButton {
    // display: flex;
    // justify-content: space-around;
    button {
      margin-right: 24px;
      @include profileButton;
    }
  }
}

.passwordResetFormInput {
  @include profileInput;
}

.passwordResetButtonsBox {
  margin-top: 24px;
  text-align: center;
  button {
    @include profileButton;
  }
}