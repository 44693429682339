.fullWidthListContainer {
  @include noPadding;
  @include addLeadingMargin;
  @include addTrailingMargin;
}

.list {
  @include addTrailingMargin(2);
}

.listTitle {
  @include addPadding;
  @include pageTitle;
  @include addTrailingMargin;
}

.subTitle {
  @include subTitle;
}
