.genericInputContainer {
  @include addTrailingMargin(.75);
  label {
    display: block;
    @include addTrailingMargin(4);
  }
  input {
    width: 100%;
  }
}
.inputRangeTicks{
  display: flex;
  justify-content: space-between;
  padding: 0 0.7rem;
  datalist{
    display: none;
  }
  i{
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 1px;
    background: #D3D3D3;
    height: 10px;
    line-height: 40px;
    margin: 0 0 20px 0;
  }
}
