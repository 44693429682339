.event-description {
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-height 0.4s ease-in-out;
    position: relative;
    max-height: 180px; /* initial height, you can adjust as per your requirements */
    padding-bottom: 30px;
  }
  
  .expand-description-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,0.8); /* slightly transparent background */
  }
  
  .past-event {
    opacity: 0.3;  /* Makes the event dimmed */
    pointer-events: none;  /* Disables any interactions */
  }
  .eventList .row:nth-child(even) {
    background-color: #f5f5f5; /* This is a light gray.  */
  }
  .light-grey-background{
    background-color: #dbd7d7; /* This is a light gray.  */
  }
  .white-background{
    background-color: #fff;
  }
  .event-image-container {
    height: 100%;
    width: 100%;
    max-height: 250px;  /* Adjust this value as per your needs */
   
  overflow: hidden;  /* This ensures that any part of the image exceeding the container's boundaries is hidden */

  }
  .bottom-border{
    border-bottom: 2px dotted grey;
  }
  
  .event-image {
    height: 100%;
    width: 100%;
    /* object-fit: cover;  This ensures the image covers the container without distortion */
     object-fit: contain; 
    
  }
  

  /* Mobile screens */
@media (max-width: 767px) {
    .title-mobile {
      font-size: 24px; /* Adjust as needed */
    }
  }
  