.modalContainer {
  margin: 0 auto;
  max-width: 372px !important;
  > div {
    padding: 36px;
  }

  b {
    color: $text-03;
  }

  .modalCloseButton {
    cursor: pointer;
  }


  .modalFooter {
    padding-bottom: 0 !important;
  }
  .close{
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
