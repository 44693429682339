.btn {
  &.pill, &.kep-login-facebook {
    line-height: 2 !important;
    font-size: $font-size-small;
    @include font-light;
    text-transform: none !important;
    border-radius: 24px/50% !important;
    border: none !important;
    outline: none !important;
    width: 200px;
    height: 45px;
  }

  &.square {
    @include font-light;
    border-radius: 0;
  }
}

[role=button]{
  cursor: pointer;
}

.btn-orange{
  background-color: #ff9400;
  border-color: #ff9400;
  color: #FFF;
  &:hover{
    background-color: #e68500;
    border-color: #e68500;
  }
}

.btn-link-light{
  color: #FFF;
}

.btn {
  white-space: normal;

  &-cta {
    background: linear-gradient($orange, darken($orange, 5%));
    border-color: $cta-border;
    color: $white;
    font-weight: 800;
    line-height: 1;
    padding: 1.0625rem 1rem 1rem;
    text-shadow: none;
    text-transform: uppercase;
    width: 100%;

    @include media-breakpoint-up(sm) {
      min-width: 350px;
      width: auto;

      &.btn-block,
      &.w-100 {
        min-width: 0;
        width: 100%;
      }
    }

    &:hover,
    &:focus {
      background: linear-gradient(darken($orange, 6%), darken($orange, 6%));
      border-color: $cta-border;
      color: $white;
    }

    &.disabled,
    &.active {
      color: $white;
    }
  }

  &-img {
    padding: 0;
  }

  &-outline {
    background: $white;
    border-color: darken($medium-gray, 10%);
    color: darken($medium-gray, 10%);
    font-weight: 800;
    line-height: 1;
    padding: 1.0625rem 1rem 1rem;
    text-shadow: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: transparentize($black, 0.9);
      border-color: darken($medium-gray, 10%);
      color: darken($medium-gray, 10%);
    }
  }

  &-upsell-gray {
    background: $medium-gray;
    border-color: $medium-gray;
    color: $white;
    line-height: 1;
    padding: 1.0625rem 1rem 1rem;
    text-shadow: none;
    width: 100%;

    @include media-breakpoint-up(sm) {
      min-width: 350px;
      width: auto;

      &.btn-block {
        width: 100%;
      }
    }

    &:hover,
    &:focus {
      background: darken($medium-gray, 10%);
      border-color: darken($medium-gray, 10%);
      color: $white;
    }
  }

  &-exit-orange {
    @extend .btn-cta;
    font-size: 1.25rem;
  }

  &-exit-gray {
    background: linear-gradient($medium-gray, darken($medium-gray, 5%));
    border-color: darken($medium-gray, 5%);
    color: $white;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1;
    padding: 1.0625rem 1rem 1rem;
    text-shadow: none;
    width: 100%;
    font-size: 1.25rem;

    &:hover,
    &:focus {
      background: linear-gradient(darken($medium-gray, 10%), darken($medium-gray, 15%));
      border-color: darken($medium-gray, 15%);
      color: $white;
    }
  }

  &-upsell-check {
    background: $green;
    border: 0;
    color: $white;
    font-weight: 800;
    line-height: 1;
    padding: 1.0625rem 1rem 1rem;
    text-shadow: none;
    text-transform: uppercase;
    width: 100%;

    &:hover,
    &:focus {
      background: darken($green, 10%);
      color: $white;
    }
  }

  &-size {
    &-350 {
      width: 100%;

      @include media-breakpoint-up(sm) {
        min-width: 350px;
        width: auto;
      }
    }
  }

  &-lg {
    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 1.1;
    }
  }

  &-link {
    border-radius: 0;
    font-size: $font-size-base;
    padding: 0;
    vertical-align: baseline;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-base * 0.8;
    }

    &-checkout {
      color: $green;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: darken($green, 10%);
      }
    }
  }

  &-download {
    padding: 0.75em;

    .btn {
      &-icon {
        font-size: $h3-font-size;
        margin: 0 0.75em 0 0;

        @include media-breakpoint-up(lg) {
          margin: 0 0.5em 0 0;
        }

        @include media-breakpoint-up(xl) {
          margin: 0 1em 0 0.5em;
        }
      }

      &-text {
        font-weight: 700;
        text-align: left;

        @include media-breakpoint-only(lg) {
          font-size: $font-size-16;
        }
      }
    }
  }

  &-scroll {
    background-color: transparentize($black, 0.7);
    bottom: 5px;
    color: $white;
    line-height: 1;
    padding: 6px 6px 2px 6px;
    position: absolute;
    right: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparentize($black, 0.5);
      color: $white;
    }

    &.scrollVisible {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.5s, opacity 0.5s;
    }
    &.scrollHidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.5s, opacity 0.5s;
    }
  }
}