.videoResourceContainer{
  .card-header{
    background: $white;
    border-bottom: none;
    // padding: $grid-gutter-width*3/2;
  }

  .videoResourceBioImg {
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 125px;
    @include bp (md) {
      height: 150px;
    }
    // @include bp(lg) {
    //   height: 200px;
    // }
  }

  .action-hour-title {
    margin: 15px 0;
  }
}
.videoResourceDownload {
  cursor: pointer;
  font-size:1.8rem;
  svg {
    font-size: 1.8rem;
  }
  a {
    color: $FRNGrey;
    text-decoration: none;
  }
}

.removeShadow{
  
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

}
