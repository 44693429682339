.recipeNotesContainer {
  padding: 3.0rem;
  @include media-breakpoint-down(xs){
    padding-left: 2.0rem;
    padding-right: 2.0rem;
  }
  @include print {
    padding: 0;
  }
}
