.scheduleContainer {
  @include addLeadingMargin;
  @include addTrailingMargin;
  @include addPadding;
  // padding-left: 100px;
}

.scheduleSessionContainer {
  @include addLeadingMargin;
}

.scheduleTitle {
  @include pageTitle;
}

.scheduleSpacing {
  @include addTrailingMargin;
}

.scheduleSubtitleStyles {
  @include subTitle;
  text-transform: none;
}

.scheduleContentTitle {
  @include contentTitle;
  text-transform: none;
}

[class*="addTrailingMargin"] {
  @include addTrailingMargin;
}