// --------------------------------------------------------------------------------
// Chat
// --------------------------------------------------------------------------------

.frn-chat {
    img{
      max-width: 100%;
      width: auto;
      height: auto;
    }

    textarea {
      field-sizing: content;
    }
  
    .btn-chat-login {
      margin: 0.5em auto;
      min-width: 0;
      width: 80%;
    }
  
    .amplify-tabs {
      padding: 1.5rem;
      margin: 0 auto;
  
      @include media-breakpoint-up(lg) {
        max-width: 600px;
        width: 100%;
      }
  
      .amplify-tabs__list {
        display: none;
      }
  
      .amplify-textfield {
        margin-bottom: 0.5rem;
  
        .amplify-label {
          font-weight: 800;
          margin-bottom: 0.25rem;
        }
  
        .amplify-field__error-message {
          color: $red;
        }
  
        input {
          @extend .form-control;
        }
      }
  
      .amplify-button {
        @extend .btn;
        @extend .btn-cta;
        margin-top: 1rem;
        min-width: none;
        width: 100%;
      }
    }
  
    .amplify-log-in-hidden {
      display: none;
    }
  
    .btn {
      &:active {
        border-color: $transparent;
      }
  
      &-reply {
        color: $purple;
        padding: 5px 0;
  
        &:focus,
        &:hover {
          color: darken($purple, 10%);
          text-decoration: underline;
        }
      }
  
      &-refresh {
        background-color: $facebook;
  
        &:focus,
        &:hover {
          color: $facebook-hover;
        }
      }
  
      &-nav {
        color: lighten($black, 30%);
        padding: 5px;
  
        &:focus,
        &:hover {
          color: $black;
          text-decoration: underline;
        }
      }
  
      &-load-more {
        background-color: darken(#f5f5f5, 10%);
  
        &:focus,
        &:hover {
          background-color: darken(#f5f5f5, 15%);
        }
      }
  
      &-post {
        background: $facebook;
        border-color: $facebook;
        color: $white;
        font-weight: 600;
        line-height: 1;
  
        &:focus,
        &:hover {
          background: $facebook-hover;
          border-color: $facebook-hover;
        }
      }
  
      &-mod {
  
        &-link {
          text-decoration: underline;
          padding: 0;
          vertical-align: baseline;
  
          &:focus,
          &:hover {
            color: lighten($black, 15%);
          }
        }
  
        &-comment {
          background-color: $alert-info-bg;
          color: $alert-info-color;
  
          &:focus,
          &:hover {
            background-color: darken($alert-info-bg, 10%);
            color: darken($alert-info-color, 10%);
          }
        }
  
        &-user {
          background-color: $alert-danger-bg;
          color: $alert-danger-color;
  
          &:focus,
          &:hover {
            background-color: darken($alert-danger-bg, 10%);
            color: darken($alert-danger-color, 10%);
          }
        }
      }
    }
  
    .chat {
      &-inner {
        position: relative;
      }
  
      &-messages {
        max-height: 55vh;
  
        @include media-breakpoint-up(md) {
          max-height: 70vh;
        }
      }
    }
  
    .message {
      &-thread {
        border-left: 1px solid $light-gray;
      }
  
      &-wrap {
        background-color: #f5f5f5;
      }
      
      &-user-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  
    // moderator elements
    .alert {
      &-warning {
        background-color: $alert-warning-bg;
        border: 1px solid $alert-warning-border;
        color: $alert-warning-color;
      }
      &-info {
        background-color: $alert-info-bg;
        border: 1px solid $alert-info-border;
        color: $alert-info-color;
      }
      &-danger {
        background-color: $alert-danger-bg;
        border: 1px solid $alert-danger-border;
        color: $alert-danger-color;
      }
      &-success {
        background-color: $alert-success-bg;
        border: 1px solid $alert-success-border;
        color: $alert-success-color;
      }
    }
  }