.userRecipesListContainer {

  .userRecipesListNav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    background: $white-02;
    cursor: pointer;
    span {
      color: $text-03;
      font-size: $font-size-regular;
      padding-top: $text-02;
      padding-bottom: $text-02;
      margin-bottom: 16px;
      margin-top: 16px;
      background-clip: border-box;

        // border-bottom: 1px solid $text-03;
      
    }
  }
  .userRecipesListRecipes {

  }
}