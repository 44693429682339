.heroHeader{
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 75px;
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    max-width: 1700px;
    margin: 0 auto;

    &.hasFullScreenVideo{
        position: absolute;
        top:0;
        left: 0;
        width:100vw;
        height:100vh;
    }
    
    @include media-breakpoint-up(sm){
        height: 60vw;
        min-height: 60vh;
        display: block;
        padding-top: 0;
    }
    //@include media-breakpoint-down(xs){
    @include media-breakpoint-up(lg){
        height: 45vw;
        min-height: unset;
    }

    &Body{
        align-self: flex-end;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        @include media-breakpoint-up(sm){
            padding-top: 0;
            padding-bottom: 0;
        }
        p{
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            margin-bottom: 0;
            
            overflow-y: 'scroll';

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
              }
        }
    }

    h1,h2,h3,h4,h5,h6{ 
        color: var(--light);
        margin-top: 0;
    }

    &Logo{
        position: relative;
        margin: 40px 0 0 0;
        filter: drop-shadow(0 5px 25px rgba(0, 0, 0, 0.5));
        text-align: center;
        width: 100%;
        left: 0; 
        z-index: 3;
        

        img{
            max-height: 100px;
            @include media-breakpoint-up(lg){
                max-height: 150px;
            }
        }

        @include media-breakpoint-up(sm){
            position: absolute;
            margin: 40px 0 0 40px; 
            text-align: left;
            filter: drop-shadow(0 0 5px rgb(0 0 0 / 0.4));
            width: auto;
            max-width: 33vw;            
        }

    }
    &Background{
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
        &Video{
            &>div{
            overflow: hidden;
            width: 100%;
            height: 100%;
            }
            iframe{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
                @include media-breakpoint-up(md){
                    height: 56.25vw;
                    
                }
                @include media-breakpoint-down(sm){
                    width: 177.78vh;
                }
            }
            &Controls{
                position: absolute;
                top: 50px;
                right: 50px;
                filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.8));
                button.btn{
                    font-size: 2rem;
                    &:hover{
                        color: #e2e6ea;
                    }
                }
                z-index: 500;
            }
            &FullScreen{
                transform: unset;
                position: absolute;
                width: 100vw !important;
                height: 100vh !important;
                top: 0;
                left: 0;
                z-index:10000;
            }
        }
    }

    &Content{
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        max-width: 1700px;
        padding: 0;
        z-index: 1;
        color: var(--light);
        .col, .col-12{
            padding-left: 0;
            padding-right: 0;
        }

        @include media-breakpoint-up(sm){
            position: absolute;
            padding-top: 70px;
            padding-bottom: 25px;
            .col, .col-12{
                padding-left: 3rem !important;
                padding-right: 3rem !important;
            }
        }

        &Background{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom,transparent, cubic-bezier(0.215, 0.610, 0.355, 1.000), #222);
            backdrop-filter: blur(40px);
            --webkit-backdrop-filter: blur(40px);
            /*
            --webkit-mask-image: linear-gradient(to bottom,transparent,rgba(0,0,0,.068) 3.3%,rgba(0,0,0,.145) 5.9%,rgba(0,0,0,.227) 8.1%,rgba(0,0,0,.313) 10.1%,rgba(0,0,0,.401) 12.1%,rgba(0,0,0,.49) 14.6%,rgba(0,0,0,.578) 17.7%,rgba(0,0,0,.661) 21.8%,rgba(0,0,0,.74) 27.1%,rgba(0,0,0,.812) 33.9%,rgba(0,0,0,.875) 42.4%,rgba(0,0,0,.927) 53%,rgba(0,0,0,.966) 66%,rgba(0,0,0,.991) 81.5%,rgba(0,0,0,.991) 100%);
            mask-image: linear-gradient(to bottom,transparent,rgba(0,0,0,.068) 3.3%,rgba(0,0,0,.145) 5.9%,rgba(0,0,0,.227) 8.1%,rgba(0,0,0,.313) 10.1%,rgba(0,0,0,.401) 12.1%,rgba(0,0,0,.49) 14.6%,rgba(0,0,0,.578) 17.7%,rgba(0,0,0,.661) 21.8%,rgba(0,0,0,.74) 27.1%,rgba(0,0,0,.812) 33.9%,rgba(0,0,0,.875) 42.4%,rgba(0,0,0,.927) 53%,rgba(0,0,0,.966) 66%,rgba(0,0,0,.991) 81.5%,rgba(0,0,0,.991) 100%);
            */
            --webkit-mask-image: linear-gradient(to bottom,transparent, cubic-bezier(0.215, 0.610, 0.355, 1.000), black);
            mask-image: linear-gradient(to bottom,transparent, cubic-bezier(0.215, 0.610, 0.355, 1.000), black);
            @include media-breakpoint-down(xs){
            }
        }
        .col {
            color: var(--light);
            display: flex;
            flex-direction: column;
        }
    }
    &Content, &Logo{
        opacity: 1;
        transition: opacity 0.8s ease-out, margin-top 0.8s step-start, z-index 0.8s step-start;
    }
    &.hideOverlays &Content, &.hideOverlays &Logo{
        opacity: 0;
        margin-top: -100vh;
        z-index: -5;
        transition: opacity 0.4s ease-out, margin-top 0.4s step-end, z-index 0.4s step-end;
    }
    .userAvatar{
        width: 100%;
        aspect-ratio: 1/1;
    }
}