.passwordLoginFormContainer {
  .inputGroupContainer {
    margin-bottom: 12px;
  }
  span {
    // font-size: $font-size-medium;
    color: $text-03;
  }
  .passwordLoginFormInputClick {
    color: $FRNLightBlue;
    &:hover {
      cursor: pointer;
    }
  }

  .passwordLoginFormActions {
    > div {
      text-align: center;
    }
    @include addTrailingMargin;
    @include addLeadingMargin;
  }
}

// The FB login button is funny!
#fb-wrapper {
  button {
    font-size: $font-size-small;
    background-color: $FRNOrange;
    padding-top: 10px;
    font-weight: 700;
    text-transform: none !important;
    height: 44px;
    &:hover {
      background-color: darken($FRNOrange, 10%);
    }
  }
}
