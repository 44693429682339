$side-bar-padding-top: 36px;
$side-bar-padding-left: 24px;

//used to hide sideBarFooter when vertical height is too small
.hideThis{
  display:none;
}
// $main-three-way-box-shadow: 0 1px 4px 0 rgba(186,202,213,0.63);
.sideBarContainer {
  box-shadow: -4px 60px 8px 3px rgba(186,202,213,0.63);
  min-height: 100vh;
  height: 100%;
  width: 300px;
  background: $white;
  // z-index just under modal background/ 1040
  z-index: 1030;
  position: fixed;
  //Remove from screen
  margin-right: -300px;
  transform: translateX(-300px);
  transition: all .4s ease-in-out;
  // span {
  //   display: none;
  // }


  &.makeSideBarVisible {
    margin-right: -100vw;
    width: 100vw;
    transform: translateX(0);
    @include bp(md) {
      width: 300px;
      margin-right: 0;
    }
    // span {
    //   display: block;
    // }
  }

  .sideBarFooter {
    padding: 20px;
    @include media-breakpoint-up(md){
        position: absolute;
        bottom:0;
    }
  }

  .sideBarNavExt {
    background: $FRNDarkPurple;
    color: white;
    padding: 16px 4px;
    height: 63px;
    // justify-content: flex-end;
  }

  .sideBarLogo {
    display: flex;
    justify-content: center;

    padding-top: $side-bar-padding-top;
    text-align: center;
    > svg{
      height: 75px;
    }
  }

  .sideBarMain {
    .sideBarOverview {
      margin-bottom: 16px;
      padding-top: 30px;
      padding-left: $side-bar-padding-left;
      // Make me a mixin?
      span {
        letter-spacing: 1.33px;
      }
    }
  }


  .sideBarSignOut {
    padding-left: $side-bar-padding-left;
    padding-top: 8px;
    height: 63px;
    span {
      cursor: pointer;
    }
  }

  @include print {
    display: none;
  }

  .sideBarArrowIcon {
    path {
      color: white;
    }
    font-size: 24px;
    cursor: pointer;
    margin-left: 24px;
  }
}
