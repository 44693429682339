.apmDownloadsList {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
  color: $text-color-body-dark;
  a {
    color: $text-color-body-dark;
  }
  svg {
    font-size: 32px;
  }
  span {
    font-size: $font-size-small;
    text-transform: uppercase;
  }
}
