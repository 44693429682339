.ordersHomeContainer {
  background: $white-02;
  padding-bottom: 120px;
  // height: 120vh;
  .ordersHomeTitle {
    @include font-light;
    margin: 36px 0;
    span {
      font-size: 24px;
    }
  }

  .ordersHomePrint {
    @include print {
      display: none;
    }
  }

  @include print {
    body {
      color: black;
    }
    // width: 600px !important;
    background-color: none;
  }

  > div {
    @include print {
      width: 66%;
    }
  }
}
