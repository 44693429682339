.myProductsContainer {
  span {
    @include font-light;
  }
  // overflow-y: hidden;
  // @include bp(md) {
  //   background-color: $white-02;
  // }
  .myProductsTitle {
    margin: 36px 0 36px 18px;
    span {
      font-size: 18px;
      @include bp(md) {
        font-size: 24px;
      }
    }
  }

  .myProductsListContainer {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin: 0px 0px 24px 0px;
      flex-basis: 100%;
      @include bp(md) {
        margin: 8px 8px 24px 8px;
        flex-basis: 46%;
        margin-bottom: 28px;
      }

      @include bp(lg) {
        flex-basis: 33%;
        margin-bottom: 36px;
      }

      cursor: pointer;
    }
  }
}
