.landingPageBG{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top:0;
  background-image: url('../images/landing-page-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #58056D;
}
