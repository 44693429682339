@import '~bootstrap/scss/_functions.scss';
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.imgBullet {
  &-img {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;

    @include media-breakpoint-up(lg) {
      border-top-right-radius: 0;
      border-bottom-left-radius: .25rem;
    }
  }
}
