.checklistItem {
  display: flex;
  align-items: center;
  background-color: $white-purple;
  padding: 8px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  > div {
    display: inline-block;
  }
  .checklistItemCheckBox {
    padding-right: 16px;
    svg{
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
