.checklistContainer, .gettingStartedVideoContainer {
  @include whiteBoxWithShadow;
  // padding: 20px;
  h5{
    // padding-bottom: 10px;
    // border-bottom: 1px solid $grey-blue;
    // margin-bottom: 40px;
  }
  &ReadOnly{
    .checklistItem{
      cursor: unset !important;
    }
  }
}
