#beacon-container {
  display: none;
  @include bp(lg) {
    display: block;
  }
}

.headerUserInfoV2 {
  &Container {
    padding: 5px 10px 5px 0px;
    cursor: pointer;
    float: right;
    height: 30px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  &Dropdown {
    padding: 0px;
    float: right;
    button {
      cursor: pointer;
      max-width: 100vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &Wrapper {
    width: 100%;
  }
}
.headerUserInfoV2ProductContainer {
  background: none;
  cursor: pointer;
  line-height: 1;
  margin-right: 14px;
  //>776px
  @include bp(sm) {
    background: orange;
    padding: 16px 10px 16px 10px;
    border-radius: 10px;
    height: 22px;
    margin-right: 18px;
  }
}
.headerUserInfoV2ProductContainerText {
  display: none;
  @include bp(sm) {
    display: inline;
    font-size: 16px;
  }
}
.headerUserInfoImg {
  text-align: center;
  width: 32px;
  height: 32px;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: #666;
}

.headerUserInfoText {
  display: none;
  @include bp(sm) {
    display: inline-block;
    height: 20px;
  }
}
