@mixin videoWrapper {
  @include addTrailingMargin;
  height: 0;
  margin-bottom: 15px;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  iframe,
  video,
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.videoWrapper {
  @include videoWrapper;
}
.loadingVideo
{
  position: absolute;
  top: 0;
  left: 15px;
  width: 100%;
  height: 100%;
  background:$FRNMediumGreyBackground;//$FRNGreyLighter;
  border-radius: 5px;
  border:thin solid $FRNGrey;
}