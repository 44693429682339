.appMainContainer {
	display: flex;
}

.appContentColumn {
	flex-basis: 100%;
	max-width: 100%;
	padding-top: 63px;
	// Use JS to add this class, give grey background to everything but checkout page
	&.addGreyBackground {
		@include bp(md) {
			background-color: $white-02;
		}
	}
	transition: padding-left .4s ease-in-out;
	&HasSidebar {
		padding-left: 300px;
		@include print {
			padding-left: 0;
			padding-top: 0;
		}
	}
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
}

.addeventatc {
 display: none;
}