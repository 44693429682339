.social-proof-wrapper{
  background-color: #7D00B5;
  color: white;
  border: 1px solid #ccc;
  /* border-radius: 15px; */
}
.social-proof-wrapper h4 {color:white;}
.social-proof {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add this to separate the image and quote */
  padding: 20px;
 
  margin-top: 20px;
  background-color: #7D00B5;
  color: white;

}

.social-proof img {
  max-width: 100px;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  border: 3px solid white;
}

.social-proof blockquote {
  margin: 0;
  flex: 1; /* Allow the quote to expand and take available space */
}

.social-proof blockquote p {
  font-style: italic;
  font-size: 20px;
}

.social-proof blockquote footer {
  text-align: right;
  padding-top: 10px;
}
