$carouselHeight: 75px;

.carouselContainer {
  @include addPadding;
  //@include addTrailingMargin(.33);
}

.carouselTitle {
  @include contentTitle;
  //@include addTrailingMargin(4);
}

.carouselStyle {
  position: relative;
  width: 100%;
  overflow: hidden;
  // height: 200px;
  .carouselControls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100%;
    background: transparent;
    .arrow {
      z-index: 1000;
      padding-top: 28px;
      color: $white;
      width: 48px;
      height: $carouselHeight;
      background: hsla(0, 100%, 0%, .5);
      text-align: center;
      cursor: pointer;
    }
  }
}

.linkStyle {
  .carouselImg {
    cursor: pointer;
    background: lightblue;
    background-size: cover;
    background-position: center;
    text-align: center;
    height: $carouselHeight;
    padding-top: 28px;
    color: white;
    // width: 125px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}