.listText{
  
    overflow: hidden;
    height:20px;
}
.listSpace
{
    width:.3em;
    height:100%;
  
    display:inline-block;
}
.list-group-item
{
    padding:0;
    border:0;
    background-color:transparent;
}
.list-group-item a:hover{
    background-color:transparent;
}

.list-group-item a{
   background-color: transparent;
  }

  .truncate {
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides any text that overflows the container */
    text-overflow: ellipsis; /* Adds an ellipsis (...) to indicate truncated text */
    max-width: 50px; /* Set the width of the container */
    width:50px;
  }

.list-group-white{
    color: var(--white);
    .list-group-item, svg{
        color: var(--white);
    }
    .list-group-item:hover{
        color: #e2e6ea;
        background: transparent;
        svg{
            color: #e2e6ea;
        }
    }
}