.FRN-recipe-card-body{
    color:white;

    min-height:52px;
    position: absolute;
    bottom:0;
    left: 0;
    width: 100%;
    font-size:$font-size-xsmall;
    background-color: rgba(98,0,184,.8);
    cursor: pointer;
     // display:flex;
    // align-items: center;
   // opacity:.7;
   // background:$FRNPurple;
}

.FRN-recipe-card-body-text{
  line-height: 1.1;
  font-size:$font-size-lg-xl;
}
