.btn-secondary{
    background:$secondary;
    max-width:100%;
    white-space: normal;
   
}

// .Day-download-link{
   
//     padding:10px 0 10px 0;
   
// }