/* Rectangle: */
.appHeaderContainer {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  transition: padding-left .4s ease-in-out;
  transition: margin-left .4s ease-in-out;
  .appContentColumnHasSidebar &{
    margin-left: -300px;
    padding-left: 300px;
  }

  color: white;
  padding: 16px 4px;
  height: 63px;
  justify-content: space-between;
  background-image: linear-gradient(to left, #7D00B5, #B400DB);
  // box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.50), inset 0 1px 3px 0 rgba(0,0,0,0.50);
  .appNavBarBrand {
    @include font-light;
    color: white;
  }

  @include print {
    display: none;
  }

  .appNavBarBarIcon {
    path {
      color: white;
    }
    font-size: 24px;
    cursor: pointer;
    margin-left: 24px;
  }
}

.headerColumn {
  text-align: center;
}

// .FRN {
//   color: white;
// }

// .button {
//   color: white;
//   background: $FRNGreen;
//   border: none;
//   padding: 0 8px;
//   &:hover {
//     color: blue;
//     cursor: pointer;
//     text-decoration: underline;
//   }
// }
