.seperatorHeader{
    height:24px;
    margin-top:0;
    margin-left:10px;
    margin-right:10px;
    width:1px;
    background-color:white;

    @include bp(lg) {
      margin-left:15px;
      margin-right:15px;
}
    
   
    }