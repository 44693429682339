.videoModal {
    width: calc((95vh - 6rem) / 9 * 16);
    max-width: 95vw;
    position: relative;
    padding-top: 3rem;
    .modal-content {
        background-color: transparent;
    }
    &-backdrop.show {
        opacity: 0.9;
        background-color: #000;
    }
    &Close.btn-link {
        position: absolute;
        top: -4rem;
        left: 0;
        text-decoration: none;
        font-size: 2rem;
        color: #DDD;
        &:hover{
        text-decoration: none;
        color: #BBB;
        }
    }
}
.audioModal .modal-content{
    aspect-ratio: 16/9;
}
