.registrationFormBack {
  text-align: center;
  @include addLeadingMargin;
  @include addTrailingMargin;
  span {
    color: $text-03;
    // font-size: $font-size-medium;
    &.clickable {
      color: $FRNLightBlue;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
