.EP{
    &Nav{
        ul{
        border-bottom:thin solid grey;
        margin-bottom:18px;
        }
        li{
        list-style: none;
        display: inline-block;
        margin-left:15px;
        }
    }
}